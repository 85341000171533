import { Component, OnInit, Input, ChangeDetectionStrategy, OnChanges, OnDestroy } from '@angular/core';
import { getTimezone } from 'src/app/helper/utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ModalFormWorkshopOrderComponent } from '../modal-form-workshop-order/modal-form-workshop-order.component';
import { Log } from 'src/app/models/log';

@Component({
  selector: 'app-change-log',
  templateUrl: './change-log.component.html',
  styleUrls: ['./change-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeLogComponent implements OnInit, OnChanges, OnDestroy {
  timeZone = getTimezone();
  defaultSlice = 4;
  sliceLogsTo = 4;
  showViewLess = false;

  @Input() logs: Log[] = [];

  private submitFormSubscription: Subscription;

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  ngOnChanges() {
  }

  showAsPlainText(log) {
    log.showText = !log.showText;
  }

  openModalForm(idWorkshopOrder) {
    const modalRef = this.modalService.open(ModalFormWorkshopOrderComponent, { size: 'lg' });
    modalRef.componentInstance.id = idWorkshopOrder;
    modalRef.componentInstance.isForm = false;
    this.submitFormSubscription = modalRef.componentInstance.submitForm.subscribe(formModel => {
      modalRef.dismiss();
    });
  }

  ngOnDestroy() {
    if (this.submitFormSubscription) { this.submitFormSubscription.unsubscribe(); }
  }
}
