import { BaseEntity } from './base-entity';

export class FreeUseDate extends BaseEntity<FreeUseDate> {
  id?: number;
  name: string;
  active: boolean;
  activeLabel: string;
  description: string;
  from: Date;
  to: Date;

  constructor(freeUseDate?) {
    super(freeUseDate);
  }

  parse(e: any): FreeUseDate {
    const freeUseDate = new FreeUseDate(e);
    return freeUseDate;
  }

  populate(freeUseDate) {
    if (freeUseDate.id) { this.id = freeUseDate.id; }
    this.name = freeUseDate.name;
    this.active = freeUseDate.active === true ? true : false;
    this.activeLabel = freeUseDate.active ? 'Activo/s' : 'Inactivo/s';
    this.description = freeUseDate.description;
    this.from = new Date(freeUseDate.from);
    this.to = new Date(freeUseDate.to);
  }
}
