import { Point } from './point';
import { BaseEntity } from './base-entity';

export class LatestPosition extends BaseEntity<LatestPosition> {
    id: number;
    createdAt?: Date;
    position: Point;
    idMedium?: number;
    idHub?: number;
    idLock?: number;
    idTrip?: number;

    constructor(latestPosition?) {
        super(latestPosition);
    }

    parse(e: any): LatestPosition {
        const latestPosition = new LatestPosition(e);
        return latestPosition;
    }

    populate(latestPosition?) {
        this.id = latestPosition.id;
        if (latestPosition.createdAt) { this.createdAt = new Date(latestPosition.createdAt); }
        this.position = new Point(latestPosition.position);
        if (latestPosition.idMedium) { this.idMedium = latestPosition.idMedium; }
        if (latestPosition.idHub) { this.idHub = latestPosition.idHub; }
        if (latestPosition.idLock) { this.idLock = latestPosition.idLock; }
        if (latestPosition.idTrip) { this.idTrip = latestPosition.idTrip; }
    }
}
