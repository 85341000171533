import { User } from './user';
import { Medium } from './medium';
import { LatestStatus } from './latest-status';
import { Hub } from './hub';
import { LineString } from './line-string';
import { BaseEntity } from './base-entity';
import { convertSecondsToTime, getTimeDifferenceToNow } from 'projects/admin/src/app/helpers/utils-admin';
import { PauseTime } from 'projects/admin/src/app/helpers/config';
import { LatestPosition } from './latest-position';
import { TripStatus } from './tripStatus';

export class Trip extends BaseEntity<Trip> {
  id: number;
  delayed: boolean;
  duration: string;
  kilometers: number;
  calories: number;
  idUser: number;
  user: User;
  idMedium: number;
  medium?: Medium;
  fromTime: Date;
  toTime?: Date;
  timeDiff?: {
    seconds: number,
    minutes: number,
    hours: number,
    days: number
  };
  fromAddress?: string;
  toAddress?: string;
  latestPosition?: LatestPosition;
  idLatestStatus?: number;
  latestStatus: LatestStatus;
  idFromHub: number;
  fromHub?: Hub;
  idToHub: number;
  toHub?: Hub;
  route?: LineString;
  tripPauses?: {
    duration: string,
    remainingTime: string,
    fromTime: Date,
    toTime: Date
  }[];
  tripStatuses?: TripStatus[];

  constructor(trip?) {
    super(trip);
  }

  parse(e: any): Trip {
    const trip = new Trip(e);
    return trip;
  }

  populate(trip) {
    this.id = trip.id;
    this.delayed = (trip.delayed) ? trip.delayed : false;
    this.duration = convertSecondsToTime(trip.duration);
    this.kilometers = trip.kilometers;
    this.calories = trip.calories;
    this.idUser = trip.idUser;
    this.user = new User(trip.user);
    this.idMedium = trip.idMedium;
    if (trip.medium) { this.medium = new Medium(trip.medium); }
    if (trip.latestPosition) {
      this.latestPosition = new LatestPosition(trip.latestPosition);
    }
    if (trip.fromAddress) { this.fromAddress = trip.fromAddress; }
    if (trip.toAddress) { this.toAddress = trip.toAddress; }
    if (trip.fromTime) { this.fromTime = new Date(trip.fromTime); }
    if (trip.toTime) { this.toTime = new Date(trip.toTime); }
    if (trip.toTime) { this.timeDiff = getTimeDifferenceToNow(new Date(trip.toTime)); }
    if (trip.idLatestStatus) { this.idLatestStatus = trip.idLatestStatus; }
    if (trip.latestStatus) { this.latestStatus = new LatestStatus(trip.latestStatus); }
    this.idFromHub = trip.idFromHub;
    if (trip.fromHub) { this.fromHub = new Hub(trip.fromHub); }
    this.idToHub = trip.idToHub;
    if (trip.toHub) { this.toHub = new Hub(trip.toHub); }
    if (trip.route) { this.route = new LineString(trip.route); }
    if (trip.tripPauses) { this.tripPauses = this.setTripPauses(trip.tripPauses); }
    if (trip.tripStatuses) { this.tripStatuses = this.setTripStatuses(trip.tripStatuses); }
  }

  setTripPauses?(tripPauses) {
    const temp = [];
    tripPauses.forEach( tripPause => {
      const remainingTime = PauseTime - tripPause.duration;
      temp.push({
        duration: convertSecondsToTime(tripPause.duration.toString()),
        remainingTime: convertSecondsToTime(remainingTime.toString()),
        fromTime: new Date(tripPause.fromTime),
        toTime: new Date(tripPause.toTime)
      });
    });
    return temp;
  }

  setTripStatuses?(tripstatuses) {
    const temp = [];
    tripstatuses.forEach(tripStatus => {
      temp.push(new TripStatus(tripStatus));
    });
    return temp;
  }
}
