import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { isNullOrEmptyOrUndefined, isEmpty } from 'src/app/helper/utils';
import * as moment from 'moment';

@Component({
  selector: 'app-input-date-time',
  templateUrl: './input-date-time.component.html',
  styleUrls: ['./input-date-time.component.scss']
})
export class InputDateTimeComponent implements OnInit, OnChanges {
  modelDatepicker;
  modelTime = {
    hour: 0,
    minute: 0
  };

  @Input() control: FormControl;
  @Input() defaultValue: string;
  @Input() showHours = true;
  @Input() placeholder = 'yyyy-mm-dd';
  @Input() disabled = false;
  @Input() useTimezone = true;

  @Output() changeDate = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    // tslint:disable-next-line:max-line-length
    if (simpleChanges.defaultValue && !isEmpty(simpleChanges.currentValue)) {
      this.patchDate(this.defaultValue);
      if (this.showHours) {
        this.patchTime(this.defaultValue);
      }
    }
  }

  patchDate(date) {
    let formattedDate;
    if (this.useTimezone) {
      formattedDate = moment(date);
    } else {
      formattedDate = moment(date).utcOffset(0);
    }
    // Use + to convert to number
    this.modelDatepicker = {
      year: +formattedDate.format('YYYY'),
      month: +formattedDate.format('MM'),
      day: +formattedDate.format('DD')
    };
  }

  patchTime(time) {
    // Use + to convert to number
    this.modelTime = {
      hour: +moment(time).format('HH'),
      minute: +moment(time).format('mm')
    };
  }

  onDateTimeChange() {
    if (isNullOrEmptyOrUndefined(this.modelDatepicker) || isNullOrEmptyOrUndefined(this.modelTime)) {
      this.control.setValue('');
      this.control.markAsTouched();
    } else {
      const dateTime = this.getDateTime();
      this.control.setValue(dateTime);
    }
    this.changeDate.emit(this.getDateTime());
  }

  getDateTime() {
    const date = `${this.modelDatepicker.year}-${this.modelDatepicker.month}-${this.modelDatepicker.day}`;
    const time = `${this.pad(this.modelTime.hour)}:${this.pad(this.modelTime.minute)}:00`;
    return this.showHours ? new Date(`${date} ${time}`) : new Date(date);
  }

  private pad(i: number): string {
    i = (isNaN(i)) ? 0 : i;
    return i < 10 ? `0${i}` : `${i}`;
  }
}
