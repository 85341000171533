import { BaseEntity } from './base-entity';
import { CatalogTicketTypePriority } from 'projects/admin/src/app/helpers/catalog';

export class TicketType extends BaseEntity<TicketType> {
    id: number;
    name: string;
    code: string;
    priority: 'HIGH' | 'MEDIUM' | 'LOW';
    priorityLabel: string;

    constructor(ticketType?) {
        super(ticketType);
    }

    parse(e: any): TicketType {
        const ticketType = new TicketType(e);
        return ticketType;
    }

    populate(ticketType) {
        this.id = ticketType.id;
        this.name = ticketType.name;
        this.code = ticketType.code;
        this.priority = ticketType.priority;
        this.priorityLabel = this.getPriorityLabel();
    }

    getPriorityLabel() {
        return CatalogTicketTypePriority[this.priority];
    }
}
