import { Component, HostBinding, ChangeDetectionStrategy } from '@angular/core';

@Component({
    // moduleId: module.id,
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class FooterComponent {
    currentDate: Date = new Date();
}
