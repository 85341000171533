import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseResponse } from 'src/app/models/base-response';
import { environment } from 'projects/admin/src/environments/environment';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { BaseEntity } from 'src/app/models/base-entity';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient,
              private loaderService: LoaderService) { }

  get<T extends BaseEntity<T>>(entity: string = '', params?, showLoader = false): Observable<BaseResponse<T>> {
    if (showLoader) { this.loaderService.show(); }
    let url = entity;
    if (params) {
      url += '?';
      // tslint:disable-next-line:forin
      for (const key in params) {
        url += key + '=' + params[key] + '&';
      }
    }
    return this.http.get<BaseResponse<T>>(environment.api.baseUrl + url);
  }

  getOne<T extends BaseEntity<T>>(entity: string = '', id?, showLoader = false, params?): Observable<BaseResponse<T>> {
    if (showLoader) { this.loaderService.show(); }
    let url = entity + '/' + id;
    if (params) {
      url += '?';
      // tslint:disable-next-line:forin
      for (const key in params) {
        url += key + '=' + params[key] + '&';
      }
    }
    return this.http.get<BaseResponse<T>>(environment.api.baseUrl + url);
  }

  post<T extends BaseEntity<T>>(url: string = '', params, showLoader = false): Observable<BaseResponse<T>> {
    if (showLoader) { this.loaderService.show(); }
    return this.http.post<BaseResponse<T>>(environment.api.baseUrl + url, params);
  }

  put<T extends BaseEntity<T>>(url: string = '', params, showLoader = false): Observable<BaseResponse<T>> {
    if (showLoader) { this.loaderService.show(); }
    return this.http.put<BaseResponse<T>>(environment.api.baseUrl + url, params);
  }

  delete<T extends BaseEntity<T>>(url: string = '', showLoader = false): Observable<BaseResponse<T>> {
    if (showLoader) { this.loaderService.show(); }
    return this.http.delete<BaseResponse<T>>(environment.api.baseUrl + url);
  }
}
