import { BaseEntity } from './base-entity';

export class LineString extends BaseEntity<LineString> {
  type = 'LineString';
  coordinates: number[][];

  constructor(lineString?) {
    super(lineString);
  }

  parse(e: any): LineString {
    const lineString = new LineString(e);
    return lineString;
  }

  populate(lineString) {
    if (lineString.coordinates) { this.coordinates = lineString.coordinates; }
  }
}
