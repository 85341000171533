import { BaseEntity } from './base-entity';
import { CatalogPenaltyType } from 'projects/admin/src/app/helpers/catalog';
import { Log } from './log';

export class Penalty extends BaseEntity<Penalty> {
  id?: number;
  name: string;
  description: string;
  type: string;
  typeLabel: string;
  amount: number;
  days: number;
  emailContent: string;
  includeTrip: boolean;

  constructor(penalty?) {
    super(penalty);
  }

  parse(e: any): Penalty {
    const penalty = new Penalty(e);
    return penalty;
  }

  populate(penalty) {
    if (penalty.id) { this.id = penalty.id; }
    this.name = penalty.name;
    this.description = penalty.description;
    this.type = penalty.type;
    this.typeLabel = this.getTypeLabel();
    this.amount = penalty.amount;
    this.days = penalty.days;
    this.emailContent = penalty.emailContent;
    this.includeTrip = penalty.includeTrip;
  }

  getTypeLabel() {
    return CatalogPenaltyType[this.type];
  }
}
