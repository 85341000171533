import { Injectable } from '@angular/core';
import { environment } from 'projects/admin/src/environments/environment';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})

export class SocketService {
  private socketUrl = environment.socket.baseUrl;
  private socket;

  constructor( ) {
    this.socket = io.connect(this.socketUrl, {
      query: `apikey=${environment.socket.key}`,
      redirect: false
    });
  }

  getEventBySocket(event: string) {
    return Observable.create( observer => {
      this.socket.on(event, (response) => {
        observer.next(response);
      });
    });
  }
}
