import { NgModule } from '@angular/core';
import { InputFileComponent } from './input-file/input-file.component';
import { SharedModule } from '../shared.module';

@NgModule({
  declarations: [
    InputFileComponent
  ],
  exports: [
    InputFileComponent
  ],
  imports: [
    SharedModule
  ]
})
export class InputFileModule { }
