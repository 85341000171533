import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { ApiService } from '../api/api.service';
import { EndpointsConstants } from '../../../helpers/endpoints';
import { BaseResponse } from 'src/app/models/base-response';
import { AdditionalCharge } from 'src/app/models/additional-charge';
import { AdditionalUse } from 'src/app/models/additional-use';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class AdditionalUseService extends ApiService {

  constructor(httpService: HttpService) {
    super(httpService);
    this.baseUrl = EndpointsConstants.additionalUse.base;
    this.logEntity = EndpointsConstants.additionalUse.log;
  }

  addCharge(obj: any, id: number, endpoint: string): Observable<BaseResponse<any>> {
    const url = `${this.baseUrl}${EndpointsConstants.additionalUse[endpoint]}/${id}`;
    return this.httpService.post(url, obj, true);
  }

  reject(obj: AdditionalUse, id: number): Observable<BaseResponse<AdditionalUse>> {
    const url = `${this.baseUrl}${EndpointsConstants.additionalUse.reject}/${id}`;
    return this.httpService.post(url, obj, true);
  }
}
