import { Component, OnInit, Input, Output, OnChanges, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { Ticket } from 'src/app/models/ticket';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TicketService } from 'projects/admin/src/app/core-admin/services/ticket/ticket.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { TicketComment } from 'src/app/models/ticket-comment';
import { CatalogFieldTypes, CatalogSocketType } from 'projects/admin/src/app/helpers/catalog';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalPreviewImageComponent } from 'projects/admin/src/app/shared-admin/components/modal-preview-image/modal-preview-image.component';
import { isEmpty, getTimezone } from 'src/app/helper/utils';
import { scrollContainerToBottom } from '../../../helpers/utils-admin';
import { SocketService } from '../../../core-admin/services/socket/socket.service';

@Component({
  selector: 'app-ticket-chat',
  templateUrl: './ticket-chat.component.html',
  styleUrls: ['./ticket-chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketChatComponent implements OnInit, OnChanges {
  chatForm: FormGroup;
  ticketComments: TicketComment[] = [];
  catalogFieldTypes = CatalogFieldTypes;
  timeZone = getTimezone();

  @ViewChild('messageContainer') messageContainer: ElementRef;

  @Input() currentTicket: Ticket;
  @Input() isModal = false;

  @Output() dismissModal = new EventEmitter();
  @Output() closeTicket = new EventEmitter();

  constructor(
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private toastr: ToastService,
    private ticketService: TicketService,
    private modalService: NgbModal,
    private socketService: SocketService
  ) {
    this.chatForm = this.fb.group({
      comment: ['', Validators.required]
    });
  }

  ngOnInit() {
    // If the component was opened by modal
    if (this.currentTicket) {
      this.getComments();
      this.updateCommentsBySocket();
    }
  }

  ngOnChanges() {
    if (this.currentTicket) {
      this.getComments();
    }
  }

  getComments() {
    const filters = {
      idTicket: this.currentTicket.id,
      order: 'id,ASC'
    };
    this.ticketService.getComments(null, null, filters).subscribe(response => {
      this.ticketComments = response.data['rows'];
      this.cdr.detectChanges();
      scrollContainerToBottom(this.messageContainer.nativeElement);
    });
  }

  updateCommentsBySocket() {
    this.socketService.getEventBySocket(CatalogSocketType.newCommentTicket).subscribe( response => {
      this.ticketComments.push(new TicketComment({
        idUser: response.idUser,
        comment: response.message
      }));
      this.getComments();
    });
  }

  onSubmit() {
    const formModel = this.chatForm.value;
    const ticketComment =  new TicketComment({
      comment: formModel.comment
    });
    if (!isEmpty(formModel.comment.trim())) {
      this.ticketService.reply(ticketComment, this.currentTicket.id).subscribe(response => {
        this.chatForm.reset();
        this.toastr.success('Comentario agregado exitosamente!');
        this.addComment(ticketComment);
      });
    }
  }

  addComment(ticketComment: TicketComment) {
    this.ticketComments.push(ticketComment);
    this.cdr.detectChanges();
    scrollContainerToBottom(this.messageContainer.nativeElement);
  }

  open(imageURL) {
    const modalRef = this.modalService.open(ModalPreviewImageComponent);
    modalRef.componentInstance.title = 'Imagen del ticket';
    modalRef.componentInstance.imageURL = imageURL;
  }

  close(event) {
    if (!event) {
      const ticket = this.currentTicket;
      ticket.isClosed = true;
      this.ticketService.edit(ticket, this.currentTicket.id).subscribe( _ => {
        this.toastr.success('Ticket cerrado exitosamente!');
        this.currentTicket.isClosed = true;
        this.closeTicket.emit();
      });
    }
  }

  clickDismissModal() {
    this.dismissModal.emit();
  }
}
