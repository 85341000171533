import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { ApiService } from '../api/api.service';
import { EndpointsConstants } from '../../../helpers/endpoints';
import { BaseResponse } from 'src/app/models/base-response';
import { Notification } from 'src/app/models/notification';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends ApiService {
  // tslint:disable-next-line:variable-name
  private _notReadedNotifications = new BehaviorSubject<number>(0);
  notReadedNotifications$ = this._notReadedNotifications.asObservable();
  // tslint:disable-next-line:variable-name
  private _readNotificationId = new BehaviorSubject<number>(undefined);
  readNotificationId$ = this._readNotificationId.asObservable();

  constructor(
    httpService: HttpService
  ) {
    super(httpService);
    this.baseUrl = EndpointsConstants.notification.base;
  }

  emitNotReadedNotifications(quantity: number) {
    this._notReadedNotifications.next(quantity);
  }

  emitReadNotificationId(id: number) {
    this._readNotificationId.next(id);
  }

  markOne(id: number): Observable<BaseResponse<Notification>> {
    const params = {
      id
    };
    const url = `${this.baseUrl}${EndpointsConstants.notification.markOne}`;
    return this.httpService.put<Notification>(url, params, true);
  }

  markAll(): Observable<BaseResponse<Notification>> {
    const url = `${this.baseUrl}${EndpointsConstants.notification.markAll}`;
    return this.httpService.put<Notification>(url, null, true);
  }
}
