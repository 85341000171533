import { Component, OnInit, HostListener, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-full-layout',
    templateUrl: './full-layout.component.html',
    styleUrls: ['./full-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class FullLayoutComponent {
    innerWidth: number;
    collapseWidth = 461;
    options = {
        direction: 'ltr'
    };

    constructor() {
        this.innerWidth = window.innerWidth;
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
      this.innerWidth = window.innerWidth;
    }
}
