import { BaseEntity } from './base-entity';

export class AssetType extends BaseEntity<AssetType> {
    id: number;
    name: string;
    code: string;
    mimeTypes: string;

    constructor(assetType?) {
        super(assetType);
    }

    parse(e: any): AssetType {
        const assetType = new AssetType(e);
        return assetType;
    }

    populate(assetType) {
        this.id = assetType.id;
        this.name = assetType.name;
        this.code = assetType.code;
        this.mimeTypes = assetType.mimeTypes;
    }
}
