import { BaseEntity } from './base-entity';
import { Log } from './log';

export class Subscription extends BaseEntity<Subscription> {
  id?: number;
  name: string;
  duration: number;
  multiplier: number;
  description: string;
  order: number;

  constructor(subscription?) {
    super(subscription);
  }

  parse(e: any): Subscription {
    const subscription = new Subscription(e);
    return subscription;
  }

  populate(subscription) {
    if (subscription.id) { this.id = subscription.id; }
    this.name = subscription.name;
    this.duration = subscription.duration;
    this.multiplier = subscription.multiplier;
    this.description = subscription.description;
    this.order = subscription.order;
  }
}
