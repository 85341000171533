import { BaseEntity } from './base-entity';

export class Notification extends BaseEntity<Notification> {
  id: number;
  severity: number;
  title: string;
  message: string;
  wasRead: boolean;
  idOperator: number;
  url: string;
  createdAt?: Date;

  constructor(notification?) {
    super(notification);
  }

  parse(e: any): Notification {
    const notification = new Notification(e);
    return notification;
  }

  populate(notification) {
    this.id = notification.id;
    this.severity = notification.severity;
    this.title = notification.title;
    this.message = notification.message;
    this.wasRead = notification.wasRead;
    this.idOperator = notification.idOperator;
    this.url = notification.url;
    if (notification.createdAt) { this.createdAt = new Date(notification.createdAt); }
  }

  getType() {
    const url = this.url.split('/');
    return url.length ? url[0] : null;
  }
}
