import { Trip } from './trip';
import { User } from './user';
import { LatestStatus } from './latest-status';
import { BaseEntity } from './base-entity';
import { CatalogAdditionalUseIdStatuses } from 'projects/admin/src/app/helpers/catalog';
import { Log } from './log';

export class AdditionalUse extends BaseEntity<AdditionalUse> {
    id: number;
    comment?: string;
    idTrip: number;
    trip?: Trip;
    idUser: number;
    user?: User;
    idLatestStatus: number;
    latestStatus?: LatestStatus;
    hideActions: string[];

    constructor(additionalUse?) {
        super(additionalUse);
    }

    parse(e: any): AdditionalUse {
        const additionalUse = new AdditionalUse(e);
        return additionalUse;
    }

    populate(additionalUse) {
        this.id = additionalUse.id;
        if (additionalUse.comment) { this.comment = additionalUse.comment; }
        this.idTrip = additionalUse.idTrip;
        if (additionalUse.trip) { this.trip = new Trip(additionalUse.trip); }
        this.idUser = additionalUse.idUser;
        if (additionalUse.user) { this.user = new User(additionalUse.user); }
        this.idLatestStatus = additionalUse.idLatestStatus;
        if (additionalUse.latestStatus) { this.latestStatus = new LatestStatus(additionalUse.latestStatus); }
        this.hideActions = this.setHideActions();
    }

    setHideActions?() {
        const items = [];
        if (this.latestStatus && this.latestStatus.status.id !== CatalogAdditionalUseIdStatuses.pendingReview) {
            items.push('charge');
            items.push('reject');
        }
        return items;
    }
}
