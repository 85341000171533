import { Sex } from './sex';
import { Log } from './log';
import { BaseEntity } from './base-entity';
import { isArray, isUndefined } from 'util';
import { CatalogProfilesLabel, DefaultAdminAvatar } from 'projects/admin/src/app/helpers/catalog';
import { getClassToCropImage } from 'projects/admin/src/app/helpers/utils-admin';

export class Administrator extends BaseEntity<Administrator> {
  id?: number;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  avatar?: string;
  avatarCropImageClass?: string;
  password?: string;
  profiles: string[];
  profilesLabel?: string;
  sex?: Sex;
  position: string;

  constructor(admin?) {
    super(admin);
  }

  parse(e: any): Administrator {
    const administrator = new Administrator(e);
    return administrator;
  }

  populate(admin) {
    if (admin.id) { this.id = admin.id; }
    this.firstName = admin.firstName;
    this.lastName = admin.lastName;
    this.fullName = `${admin.firstName} ${admin.lastName}`;
    this.email = admin.email;
    this.avatar = (!isUndefined(admin.avatar)) ? admin.avatar : DefaultAdminAvatar;
    if (admin.avatarCropImageClass) { this.avatarCropImageClass = getClassToCropImage(admin.avatar); }
    if (admin.password) { this.password = admin.password; }
    if (admin.sex) { this.sex = admin.sex; }
    this.profiles = admin.profiles;
    if (isArray(admin.profiles)) { this.profilesLabel = this.getProfilesLabel(admin.profiles); }
    this.position = admin.position;
  }

  getProfilesLabel?(adminProfiles) {
    const profiles = [];
    adminProfiles.forEach( profile => {
      if (CatalogProfilesLabel[profile]) {
        profiles.push(CatalogProfilesLabel[profile]);
      }
    });
    return profiles.join(', ');
  }
}
