import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { LockService } from '../../../core-admin/services/lock/lock.service';
import * as alertFunctions from 'src/app/core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-shutdown-lock',
  templateUrl: './shutdown-lock.component.html',
  styleUrls: ['./shutdown-lock.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShutdownLockComponent implements OnInit {
  isDisabled = false;

  @Input() imei: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private lockService: LockService
  ) { }

  ngOnInit() {
  }

  shutdownLock() {
    const data = {
      title: '¿Estás seguro que deseas apagar el lock?',
      type: 'question'
    };
    alertFunctions.confirmAction(data).then( result => {
      if (result.value) {
        this.lockService.shutdown(this.imei).subscribe( _ => {
          this.isDisabled = true;
          this.cdr.detectChanges();
        });
      }
    });
  }
}
