import { Injectable } from '@angular/core';
import { EndpointsConstants } from '../../../helpers/endpoints';
import { HttpService } from '../http/http.service';
import { ApiService } from '../api/api.service';
import { AuthService } from '../auth/auth.service';
import { BaseResponse } from 'src/app/models/base-response';
import { Administrator } from 'src/app/models/administrator';
import { isNullOrEmptyOrUndefined, generateParamsForRoutes } from 'src/app/helper/utils';
import { setLocalData } from 'src/app/helper/storage';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/internal/operators/tap';

@Injectable({
  providedIn: 'root'
})
export class AdministratorService extends ApiService {

  constructor(
    httpService: HttpService,
    private authService: AuthService
  ) {
    super(httpService);
    this.baseUrl = EndpointsConstants.admin.base;
    this.logEntity = EndpointsConstants.admin.base;
  }

  putMe(administrator: Administrator): Observable<BaseResponse<Administrator>> {
    const url = this.baseUrl + EndpointsConstants.admin.me;
    return this.httpService.put<Administrator>(url, administrator, true)
      .pipe(tap(response => {
        const user = response.data[0];
        if (!isNullOrEmptyOrUndefined(user)) {
          setLocalData('smod-admin-currentUser', user);
          this.authService.getLoggedUser();
        }
      }));
  }

  getMe(filters: any = []): Observable<BaseResponse<Administrator>> {
    const url = this.baseUrl + EndpointsConstants.admin.me;
    const params = generateParamsForRoutes(filters);
    return this.httpService.get<Administrator>(url, filters, true);
  }
}
