import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';


import { CONTENT_ROUTES } from './shared-admin/routes/content-layout.routes';

import { FULL_ROUTES } from './shared-admin/routes/full-layout.routes';
import { FullLayoutComponent } from './core-admin/layouts/full/full-layout.component';
import { ContentLayoutComponent } from './core-admin/layouts/content/content-layout.component';
import { AuthGuard } from './core-admin/services/auth-guard/auth-guard.service';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  { path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: FULL_ROUTES, canActivate: [AuthGuard] },
  { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
