import { BaseEntity } from './base-entity';

export class Status extends BaseEntity<Status> {
  id: number;
  createdAt?: Date;
  name?: string;
  code?: string;
  description?: number;
  idStatusType?: number;

  constructor(status?) {
    super(status);
  }

  parse(e: any): Status {
    const status = new Status(e);
    return status;
  }

  populate(status) {
    this.id = status.id;
    if (status.createdAt) { this.createdAt = new Date(status.createdAt); }
    if (status.name) { this.name = status.name; }
    if (status.code) { this.code = status.code; }
    if (status.description) { this.name = status.description; }
    if (status.idStatusType) { this.idStatusType = status.idStatusType; }
  }
}
