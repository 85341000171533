import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Sanction } from 'src/app/models/sanction';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { PenaltyService } from '../../../core-admin/services/penalty/penalty.service';
import { Penalty } from 'src/app/models/penalty';
import { getTimezone } from 'src/app/helper/utils';
import { convertEditorTextToPlainText } from '../../../helpers/utils-admin';
import { Log } from 'src/app/models/log';
import { Subscription } from 'rxjs/internal/Subscription';
import { AdditionalUse } from 'src/app/models/additional-use';
import { AdditionalUseService } from '../../../core-admin/services/additional-use/additional-use.service';
import { BaseResponse } from 'src/app/models/base-response';
import { SecondaryColor } from '../../../helpers/config';
import { SanctionService } from '../../../core-admin/services/sanction/sanction.service';

@Component({
  selector: 'app-modal-form-sanction',
  templateUrl: './modal-form-sanction.component.html',
  styleUrls: ['./modal-form-sanction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalFormSanctionComponent implements OnInit, OnDestroy {
  secondaryColor = SecondaryColor;
  timeZone = getTimezone();
  sanctionForm: FormGroup;
  penalties: Penalty[];
  logs: BaseResponse<Log>;
  idAdditionalUse: number;
  private routerSubscription: Subscription;

  @Input() title: string;
  @Input() currentSanction?: Sanction;
  @Input() isForm: boolean;
  @Input() showIcon = true;
  @Input() showButtons = true;
  @Input() idTrip;
  @Input() buttonText = 'Guardar';

  @Output() submitForm = new EventEmitter();

  constructor(
    private cdr: ChangeDetectorRef,
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private router: Router,
    private datePipe: DatePipe,
    private additionalUseService: AdditionalUseService,
    private sanctionService: SanctionService,
    private penaltyService: PenaltyService
  ) {
    this.subscribeRouterEvent();
    this.sanctionForm = this.fb.group({
      idPenalty: [null, Validators.required],
      useAdditionalUse: [false, Validators.required],
      amount: [0, Validators.required],
      expirationDate: ['', Validators.required],
      sanctionDays: ['', Validators.required],
      blockedDays: ['', Validators.required],
      comment: ['', Validators.required]
    });
  }

  ngOnInit() {
    const date = new Date();
    // tslint:disable-next-line:radix
    const year = (this.currentSanction) ? parseInt(this.datePipe.transform(this.currentSanction.expirationDate, 'yyyy', this.timeZone)) : date.getFullYear();
    // tslint:disable-next-line:radix
    const month = (this.currentSanction) ? parseInt(this.datePipe.transform(this.currentSanction.expirationDate, 'MM', this.timeZone)) : date.getMonth() + 2;
    // tslint:disable-next-line:radix
    const day = (this.currentSanction) ? parseInt(this.datePipe.transform(this.currentSanction.expirationDate, 'dd', this.timeZone)) + 1 : date.getDay();
    if (this.currentSanction) {
      // Edit Form
      this.sanctionForm.patchValue(this.currentSanction);
    } else {
      // Add Form
      this.getPenalties();
    }
    this.sanctionForm.get('expirationDate').setValue({
      year,
      month,
      day
    });
    if (this.idTrip && this.isForm) {
      this.getAdditionalUse();
    }
    if (!this.isForm) {
      this.getLogs();
    }
  }

  getLogs() {
    this.sanctionService.getLogs(this.currentSanction.id).subscribe( response => {
      this.logs = new BaseResponse<Log>(response, Log);
      this.cdr.detectChanges();
    });
  }

  getAdditionalUse() {
    const filters = {
      idTrip: this.idTrip
    };
    this.additionalUseService.get<AdditionalUse>(null, null, filters).subscribe(
      response => {
        // One additional use per medium
        const additionalUses = new BaseResponse<AdditionalUse>(response, AdditionalUse);
        if (additionalUses.data['rows'].length) {
          this.idAdditionalUse = additionalUses.data['rows'][0].id;
        }
        this.cdr.detectChanges();
      }
    );
  }

  getPenalties() {
    const filters = [];
    if (!this.idTrip) {
      filters['includeTrip'] = false;
    }
    this.penaltyService.get<Penalty>(null, null, filters).subscribe(
      response => {
        const penalties = new BaseResponse<Penalty>(response, Penalty);
        this.penalties = penalties.data['rows'];
        this.cdr.detectChanges();
      }
    );
  }

  subscribeRouterEvent() {
    this.routerSubscription = this.router.events.subscribe( _ => {
      this.activeModal.dismiss();
    });
  }

  changePenalty(penalty) {
    if (penalty) {
      this.sanctionForm.get('sanctionDays').setValue(penalty.days);
      this.sanctionForm.get('amount').setValue(parseInt(penalty.amount, 10));
      this.sanctionForm.get('comment').setValue(penalty.emailContent);
      this.cdr.detectChanges();
    } else {
      this.sanctionForm.get('sanctionDays').reset();
      this.sanctionForm.get('amount').reset();
      this.sanctionForm.get('comment').reset();
      this.cdr.detectChanges();
    }
  }

  onSubmit() {
    const formModel = this.sanctionForm.value;
    const newSanction = {
      idPenalty: formModel.idPenalty,
      idAdditionalUse: formModel.useAdditionalUse ? this.idAdditionalUse : undefined,
      amount: parseInt(formModel.amount, 10),
      expirationDate: formModel.expirationDate,
      sanctionDays: formModel.sanctionDays,
      blockedDays: formModel.blockedDays,
      comment: convertEditorTextToPlainText(formModel.comment)
    };
    this.submitForm.emit(newSanction);
  }

  ngOnDestroy() {
    if (this.routerSubscription) { this.routerSubscription.unsubscribe(); }
  }
}
