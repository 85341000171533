import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { Lock } from 'src/app/models/lock';
import { DatePipe } from '@angular/common';
import { getBatteryPercent } from '../../../helpers/utils-admin';
import { getTimezone } from 'src/app/helper/utils';

@Component({
  selector: 'app-header-detail-lock',
  templateUrl: './header-detail-lock.component.html',
  styleUrls: ['./header-detail-lock.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderDetailLockComponent implements OnInit, OnChanges {
  timeZone = getTimezone();
  batteryPercent: number = null;
  locked: boolean;
  timeDiff: object;

  @Input() lock: Lock;
  @Input() showMedium = true;

  constructor(
    private datePipe: DatePipe
  ) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (this.lock && changes.lock && changes.lock.firstChange) {
      // Calculate percent of battery
      if (this.lock.latestHeartbeat) {
        this.batteryPercent = getBatteryPercent(this.lock.latestHeartbeat.batteryVolume);
      }
      if (this.lock.latestConnection) {
        const toTime = this.datePipe.transform(this.lock.latestConnection, 'yyyy-MM-dd HH:mm:ss', this.timeZone);
        // this.timeDiff = getTimeDifferenceToNow(toTime);
      }
    }
  }

}
