export class Metadata {
    totalRegistries: number;
    pages: number;
    page: number;
    pageSize: number;

    constructor(metadata) {
        this.totalRegistries = metadata.totalRegistries;
        this.pages = metadata.pages;
        this.page = metadata.page;
        this.pageSize = metadata.pageSize;
    }
}
