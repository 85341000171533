import { BaseEntity } from './base-entity';
import { Administrator } from './administrator';
import { CatalogLogFieldHasHTMLContent } from 'projects/admin/src/app/helpers/catalog';
import { FreeUseDate } from './free-use-date';
import { Workshop } from './workshop';
import { ReplacementPart } from './replacement-part';

export class Log extends BaseEntity<Log> {
  createdAt: Date;
  oldValue: string;
  newValue: string;
  field: string;
  idAdmin: number;
  idFreeUseDate?: number;
  freeUseDate?: FreeUseDate;
  admin?: Administrator;
  showText = true;
  idWorkshopOrder?: number;
  workshopOrder?: Workshop;
  replacementPart?: ReplacementPart;
  idReplacementPart?: number;
  stokLogWorkshopOrder?: boolean;

  constructor(log?) {
    super(log);
  }

  parse(e: any): Log {
    const log = new Log(e);
    return log;
  }

  populate(log) {
    this.createdAt = new Date(log.createdAt);
    this.oldValue = log.oldValue ? log.oldValue : 'Vacío';
    this.newValue = log.newValue ? log.newValue : 'Vacío';
    this.idAdmin = log.idAdmin;
    this.field = log.field;
    if (log.idFreeUseDate) { this.idFreeUseDate = log.idFreeUseDate; }
    if (log.freeUseDate) { this.freeUseDate = new FreeUseDate(log.freeUseDate); }
    if (log.admin) { this.admin = new Administrator(log.admin); }
    if (log.idWorkshopOrder) { this.idWorkshopOrder = log.idWorkshopOrder; }
    if (log.workshopOrder) {
      this.workshopOrder = new Workshop(log.workshopOrder);
      this.stokLogWorkshopOrder = this.idWorkshopOrder && this.field === 'stock';
    }
    if (log.idReplacementPart) { this.idReplacementPart = log.idReplacementPart; }
    if (log.replacementPart) {
      this.replacementPart = new ReplacementPart(log.replacementPart);
    }
  }

  hasHtmlContent() {
    return CatalogLogFieldHasHTMLContent.includes(this.field);
  }
}
