import swal, { SweetAlertType } from 'sweetalert2';

export function deleteAction(title = '¿Estás seguro?', cancelButtonText = 'Cancelar', confirmButtonText = 'Sí') {
    return swal({
        title,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0CC27E',
        cancelButtonColor: '#FF586B',
        confirmButtonText,
        cancelButtonText,
        confirmButtonClass: 'btn btn-success mw-50 btn-raised mr-5',
        cancelButtonClass: 'btn btn-danger mw-50 btn-raised',
        buttonsStyling: false
    });
}

export function deleteSuccess(title = 'Borrado!') {
    swal({
        title,
        type: 'success'
    });
}

export function confirmAction(data) {
    return swal({
        title: data.title,
        text: data.text,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0CC27E',
        cancelButtonColor: '#FF586B',
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
    });
}

export function confirmSuccess(title, text = '', type: SweetAlertType = 'success') {
    swal({
        title,
        text,
        type
    });
}
