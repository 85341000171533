import { Subscription } from './subscription';
import { isNull, isNullOrEmptyOrUndefined } from '../helper/utils';
import { BaseEntity } from './base-entity';
import { getClassToCropImage } from 'projects/admin/src/app/helpers/utils-admin';
import { LatestStatus } from './latest-status';
import { LatestPosition } from './latest-position';
import { Trip } from './trip';
import { DefaultAdminAvatar } from 'projects/admin/src/app/helpers/catalog';
import { Log } from './log';
import { CatalogUserType } from '../../../projects/admin/src/app/helpers/catalog';

export class User extends BaseEntity<User> {
  id?: number;
  active: boolean;
  condition: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  identification: string;
  birthday: string;
  gender: string;
  phone: string;
  identificationBackImageURL: string;
  identificationFrontImageURL: string;
  faceImageURL: string;
  avatar?: string;
  avatarCropImageClass: string;
  foreign: boolean;
  renaper: boolean;
  validatedEmail: boolean;
  validatedDocumentation: boolean;
  idLatestStatus: number;
  latestStatus?: LatestStatus;
  latestTrip?: Trip;
  currentSubscription?: {
    startDate: Date,
    endDate: Date,
    idSubscription: number,
    subscription: Subscription
  };
  latestPosition?: LatestPosition;
  hasSpecialNeeds: boolean;
  test: boolean;
  testLabel?: string;

  constructor(user?) {
    super(user);
  }

  parse(e: any): User {
    const user = new User(e);
    return user;
  }

  populate(user?) {
    if (user.id) { this.id = user.id; }
    this.active = user.active;
    this.condition = this.active ? 'Activo' : 'Inactivo';
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.fullName = `${user.firstName} ${user.lastName}`;
    this.email = user.email;
    this.identification = user.identification;
    this.birthday = user.birthday;
    this.gender = user.gender;
    this.phone = user.phone;
    this.identificationBackImageURL = user.identificationBackImageURL;
    this.identificationFrontImageURL = user.identificationFrontImageURL;
    this.faceImageURL = user.faceImageURL;
    this.avatar = (!isNullOrEmptyOrUndefined(user.avatar)) ? user.avatar : DefaultAdminAvatar;
    this.avatarCropImageClass = user.avatar ? getClassToCropImage(user.avatar) : getClassToCropImage(DefaultAdminAvatar);
    this.foreign = user.foreign;
    this.renaper = user.renaper;
    this.validatedEmail = user.validatedEmail;
    this.validatedDocumentation = user.validatedDocumentation;
    this.idLatestStatus = user.idLatestStatus;
    if (user.latestStatus) { this.latestStatus = new LatestStatus(user.latestStatus); }
    if (user.latestTrip) { this.latestTrip = new Trip(user.latestTrip); }
    if (user.currentSubscription) {
      this.currentSubscription = {
        startDate: (!isNull(user.currentSubscription.startDate)) ? new Date(user.currentSubscription.startDate) : null,
        endDate: (!isNull(user.currentSubscription.startDate)) ? new Date(user.currentSubscription.endDate) : null,
        idSubscription: user.currentSubscription.idSubscription,
        subscription: new Subscription(user.currentSubscription.subscription)
      };
    }
    if (user.latestPosition) {
      this.latestPosition = new LatestPosition(user.latestPosition);
    }
    this.hasSpecialNeeds = user.hasSpecialNeeds;
    this.test = user.test;
    this.testLabel = user.test ? CatalogUserType.test : CatalogUserType.real;
  }

  getNationality() {
    return this.foreign ? 'Turista' : (this.gender === 'F') ? 'Argentina' : 'Argentino';
  }
}
