import { MediumType } from './medium-type';
import { Lock } from './lock';
import { LatestStatus } from './latest-status';
import { Log } from './log';
import { BaseEntity } from './base-entity';
import { Hub } from './hub';
import { Trip } from './trip';
import { CatalogConditionMedium } from 'projects/admin/src/app/helpers/catalog';
import { LatestPosition } from './latest-position';

export class Medium extends BaseEntity<Medium> {
  id?: number;
  active: boolean;
  incident: boolean;
  createdAt?: Date;
  idLatestPosition?: number;
  idLatestStatus?: number;
  idLatestTrip?: number;
  operational?: boolean;
  condition: string;
  plate: string;
  idLock: number;
  lock?: Lock;
  idHub?: number;
  hub?: Hub;
  idMediumType: number;
  mediumType?: MediumType;
  idStatus: number;
  latestStatus?: LatestStatus;
  latestTrip?: Trip;
  currentTrip?: Trip;
  latestPosition?: LatestPosition;
  reserved?: boolean;
  visible?: boolean;

  constructor(medium?) {
    super(medium);
  }

  parse(e: any): Medium {
    const medium = new Medium(e);
    return medium;
  }

  populate(medium) {
    if (medium.id) { this.id = medium.id; }
    this.active = medium.active ? medium.active : false;
    this.condition = medium.active ? CatalogConditionMedium.active : CatalogConditionMedium.inactive;
    this.incident = medium.incident ? medium.incident : false;
    if (medium.createdAt) { this.createdAt = new Date(medium.createdAt); }
    if (medium.idLatestPosition) { this.idLatestPosition = medium.idLatestPosition; }
    if (medium.idLatestStatus) { this.idLatestStatus = medium.idLatestStatus; }
    if (medium.idLatestTrip) { this.idLatestTrip = medium.idLatestTrip; }
    this.operational = (medium.operational) ? medium.operational : false;
    this.plate = medium.plate;
    if (medium.latestPosition) {
      this.latestPosition = new LatestPosition(medium.latestPosition);
    }
    // Status from form
    if (medium.idStatus) { this.idStatus = medium.idStatus; }
    // Status from api
    if (medium.latestStatus) {
      this.idStatus = medium.latestStatus.idStatus;
      this.latestStatus = new LatestStatus(medium.latestStatus);
    }
    this.idLock = medium.idLock;
    if (medium.lock) { this.lock = new Lock(medium.lock); }
    this.idHub = medium.idHub;
    if (medium.hub) { this.hub = new Hub(medium.hub); }
    this.idMediumType = medium.idMediumType;
    if (medium.mediumType) { this.mediumType = new MediumType(medium.mediumType); }
    if (medium.latestTrip) { this.latestTrip = new Trip(medium.latestTrip); }
    if (medium.currentTrip) { this.currentTrip = new Trip(medium.currentTrip); }
    if (medium.reserved) { this.reserved = medium.reserved; }
    if (medium.visible) { this.visible = medium.visible; }
  }
}
