import { Penalty } from './penalty';
import { User } from './user';
import { Trip } from './trip';
import { AdditionalUse } from './additional-use';
import { AdditionalCharge } from './additional-charge';
import { BaseEntity } from './base-entity';
import { LatestStatus } from './latest-status';
import { CatalogSanctionIdStatuses } from 'projects/admin/src/app/helpers/catalog';
import { Log } from './log';

export class Sanction extends BaseEntity<Sanction> {
  id?: number;
  createdAt: Date;
  idPenalty?: number;
  penalty?: Penalty;
  idUser?: number;
  user?: User;
  idTrip?: number;
  trip?: Trip;
  idAdditionalUse?: number;
  additionalUse?: AdditionalUse;
  idAdditionalCharge?: number;
  additionalCharge?: AdditionalCharge;
  amount: number;
  expirationDate: Date;
  sanctionDays: number;
  blockedDays: number;
  comment?: string;
  hideActions: string[];
  idStatus?: number;
  idLatestStatus: number;
  latestStatus: LatestStatus;

  constructor(sanction?) {
    super(sanction);
  }

  parse(e: any): Sanction {
    const sanction = new Sanction(e);
    return sanction;
  }

  populate(sanction) {
    if (sanction.id) { this.id = sanction.id; }
    if (sanction.createdAt) { this.createdAt = new Date(sanction.createdAt); }
    if (sanction.idPenalty) { this.idPenalty = sanction.idPenalty; }
    if (sanction.penalty) { this.penalty = new Penalty(sanction.penalty); }
    if (sanction.idUser) { this.idUser = sanction.idUser; }
    if (sanction.user) { this.user = new User(sanction.user); }
    if (sanction.idTrip) { this.idTrip = sanction.idTrip; }
    if (sanction.trip) { this.trip = new Trip(sanction.trip); }
    if (sanction.idAdditionalUse) { this.idAdditionalUse = sanction.idAdditionalUse; }
    if (sanction.additionalUse) { this.additionalUse = new AdditionalUse(sanction.additionalUse); }
    if (sanction.idAdditionalcharge) { this.idAdditionalCharge = sanction.idAdditionalCharge; }
    if (sanction.additionalCharge) { this.additionalCharge = new AdditionalCharge(sanction.additionalCharge); }
    this.amount = sanction.amount;
    this.expirationDate = sanction.expirationDate;
    this.sanctionDays = sanction.sanctionDays;
    this.blockedDays = sanction.blockedDays;
    if (sanction.comment) { this.comment = sanction.comment; }
    this.idLatestStatus = sanction.idLatestStatus;
    if (sanction.idStatus) {this.idStatus = sanction.idStatus; }
    if (sanction.latestStatus) { this.latestStatus = new LatestStatus(sanction.latestStatus); }
    this.hideActions = this.setHideActions();
  }

  setHideActions?() {
      const items = [];
      if (this.latestStatus && this.latestStatus.status.id !== CatalogSanctionIdStatuses.inReview) {
          items.push('charge');
          items.push('reject');
          items.push('editWithEvent');
      }
      return items;
  }
}
