import { BaseEntity } from './base-entity';

export class MediumType extends BaseEntity<MediumType> {
  id: number;
  name: string;
  code: string;

  constructor(mediumType?) {
    super(mediumType);
  }

  parse(e: any): MediumType {
    const mediumType = new MediumType(e);
    return mediumType;
  }

  populate(mediumType) {
    this.id = mediumType.id;
    this.name = mediumType.name;
    this.code = mediumType.code;
  }
}

