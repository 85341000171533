import { Log } from './log';
import { BaseEntity } from './base-entity';

export class WorkshopReason extends BaseEntity<WorkshopReason> {
  id?: number;
  name: string;
  description: string;

  constructor(admin?) {
    super(admin);
  }

  parse(e: any): WorkshopReason {
    const workshopReason = new WorkshopReason(e);
    return workshopReason;
  }

  populate(workshopReason) {
    if (workshopReason.id) { this.id = workshopReason.id; }
    this.name = workshopReason.name;
    this.description = workshopReason.description;
  }
}
