import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { getLocalData } from './storage';
import { isNullOrUndefined } from 'util';

export function isNullOrEmptyOrUndefined(field) {

  let valid = false;
  if (field === '' || field === null || field === undefined) {
    valid = true;
  }

  return valid;
}

export function isNull(field) {

  let valid = false;
  if (field === null) {
    valid = true;
  }

  return valid;
}

export function isEmpty(field) {

  let valid = true;

  // Object
  if (typeof field === 'object') {
    for (const key in field) {
        if (field.hasOwnProperty(key)) {
            valid = false;
        }
    }
  } else if (field !== '') {
    valid = false;
  }

  // Array
  if (Array.isArray(field)) {
    if (field.length > 0) {
      valid = false;
    }
  }

  return valid;
}

export function isUndefined(field) {

  let valid = false;
  if (field === undefined) {
    valid = true;
  }

  return valid;
}

export function toModelDate(date: NgbDateStruct): Date {
  return date ? new Date(date.year + '-' + date.month + '-' + date.day) : null;
}

export function validateAllFields(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormGroup) {
      this.validateAllFields(control);
    }
  });
}

export function passwordMatchValidator(frm: FormGroup) {
  return frm.controls.password.value ===
    frm.controls.repeatPassword.value ? null : { mismatch: true };
}


export function generateParamsForRoutes(filters: any, page: number = null, pageSize: number = null) {
  const params: any = {};
  if (!isNull(page)) { params.page = page; }
  if (!isNull(pageSize)) { params.pageSize = pageSize; }
  if (filters) {
    // tslint:disable-next-line:forin
    for (const key in filters) {
      if (!isNullOrUndefined(filters[key])) {
        params[key] = filters[key];
      }
    }
  }

  return params;
}

export function generateOption(arr: any) {
  const options: any = [];
  // tslint:disable-next-line:forin
  for (const key in arr) {
    const opt = { key: '', name: '' };
    opt.key = key;
    opt.name = arr[key];
    options.push(opt);
  }
  return options;
}

export enum sex {
  'M' = 'Masculino',
  'F' = 'Femenino',
  'NE' = 'No especifica'
}

export function isObject(field) {
  return (typeof field === 'object') ? true : false;
}

export function validatePenaltyEmailContent(emailContent: string, relativeToTrip: boolean) {
  // Relative to trips. Objects valids: user and trip
  // Not relative to trips. Object valid: user
  const bannedVariables = [];
  const allowedObjects = (relativeToTrip) ? ['user', 'trip'] : ['user'];
  const variablesInText = emailContent.match(/\{(.*?)\}/g);
  if (!isNullOrEmptyOrUndefined(variablesInText)) {
    variablesInText.forEach( item => {
      const objectName = item.replace('{', '').replace('}', '').split('.')[0];
      const index = allowedObjects.indexOf(objectName);
      if (index === -1) {
        bannedVariables.push(item);
      }
    });
  }
  return {
    valid: (bannedVariables.length) ? false : true,
    bannedVariables
  };
}

export function getTimezone() {
  const settings = getLocalData('smod-admin-settings');
  const setting = settings.find( item => item.code === 'TIMEZONE');
  return !isNullOrEmptyOrUndefined(setting) ? setting.value : 'UTC';
}

export function exportCSV(data: string, name: string) {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = name + '.csv';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

export function exportFile(data: any, fileName: string, fileType = 'text/csv', fileExtension = 'csv') {
  const blob = new Blob([data], { type: fileType });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName + '.' + fileExtension;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

export function changeValidators(fields: Array<string>, form: FormGroup | AbstractControl, validators: any) {
  fields.forEach(element => {
    form.get(element).setValidators(validators);
    form.get(element).updateValueAndValidity();
  });
}
