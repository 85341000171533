import { User } from './user';
import { Administrator } from './administrator';
import { Trip } from './trip';
import { Medium } from './medium';
import { Lock } from './lock';
import { AdditionalUse } from './additional-use';
import { AdditionalCharge } from './additional-charge';
import { Sanction } from './sanction';
import { TicketType } from './ticket-type';
import { TicketComment } from './ticket-comment';
import { TicketFailure } from './ticket-failure';
import { TicketAsset } from './ticket-asset';
import { BaseEntity } from './base-entity';

export class Ticket extends BaseEntity<Ticket> {
    id?: number;
    isClosed?: boolean;
    status?: string;
    wasReaded?: boolean;
    userFavourite?: boolean;
    adminFavourite?: boolean;
    automatic?: boolean;
    closedAt?: Date;
    priority?: string;
    idUser?: number;
    user?: User;
    idAdmin?: Administrator;
    admin?: Administrator;
    idTrip?: number;
    trip?: Trip;
    idMedium?: number;
    medium?: Medium;
    idLock?: number;
    lock?: Lock;
    idAdditionalUse?: number;
    additionalUse?: AdditionalUse;
    idAdditionalCharge?: number;
    additionalCharge?: AdditionalCharge;
    idSanction?: number;
    sanction?: Sanction;
    idTicketType: number;
    ticketType?: TicketType;
    ticketComment?: TicketComment[] | TicketComment;
    idLatestComment?: number;
    latestComment?: TicketComment;
    ticketFailure?: TicketFailure;
    ticketAsset?: TicketAsset[];
    createdAt?: Date;

    constructor(ticket?) {
        super(ticket);
    }

    parse(e: any): Ticket {
        const ticket = new Ticket(e);
        return ticket;
    }

    populate(ticket) {
        if (ticket.id) { this.id = ticket.id; }
        if (ticket.isClosed) { this.isClosed = ticket.isClosed; }
        this.status = (ticket.isClosed) ? 'Cerrado' : 'Abierto';
        if (ticket.wasReaded) { this.wasReaded = ticket.wasReaded; }
        if (ticket.userFavourite) { this.userFavourite = ticket.userFavourite; }
        if (ticket.adminFavourite) { this.adminFavourite = ticket.adminFavourite; }
        if (ticket.automatic) { this.automatic = ticket.automatic; }
        if (ticket.closedAt) { this.closedAt = new Date(ticket.closedAt); }
        if (ticket.priority) { this.priority = ticket.priority; }
        if (ticket.idUser) { this.idUser = ticket.idUser; }
        if (ticket.user) { this.user = new User(ticket.user); }
        if (ticket.idAdmin) { this.idAdmin = ticket.idAdmin; }
        if (ticket.admin) { this.admin = new Administrator(ticket.admin); }
        if (ticket.idTrip) { this.idTrip = ticket.idTrip; }
        if (ticket.trip) { this.trip = new Trip(ticket.trip); }
        if (ticket.idMedium) { this.idMedium = ticket.idMedium; }
        if (ticket.medium) { this.medium = new Medium(ticket.medium); }
        if (ticket.idLock) { this.idLock = ticket.idLock; }
        if (ticket.lock) { this.lock = new Lock(ticket.lock); }
        if (ticket.idAdditionalUse) { this.idAdditionalUse = ticket.idAdditionalUse; }
        if (ticket.additionalUse) { this.additionalUse = new AdditionalUse(ticket.additionalUse); }
        if (ticket.idAdditionalCharge) { this.idAdditionalCharge = ticket.idAdditionalCharge; }
        if (ticket.additionalCharge) { this.additionalCharge = new AdditionalCharge(ticket.additionalCharge); }
        if (ticket.idSanction) { this.idSanction = ticket.idSanction; }
        if (ticket.sanction) { this.sanction = new Sanction(ticket.sanction); }
        this.idTicketType = ticket.idTicketType;
        if (ticket.ticketType) { this.ticketType = new TicketType(ticket.ticketType); }
        if (ticket.ticketComment) { this.ticketComment = ticket.ticketComment; }
        if (ticket.idLatestComment) { this.idLatestComment = ticket.idLatestComment; }
        if (ticket.latestComment) { this.latestComment = new TicketComment(ticket.latestComment); }
        if (ticket.ticketFailure) { this.ticketFailure = new TicketFailure(ticket.ticketFailure); }
        if (ticket.ticketAsset) { this.ticketAsset = this.setAssets(ticket.ticketAsset); }
        if (ticket.createdAt) { this.createdAt = new Date(ticket.createdAt); }
    }

    setAssets?(assets) {
        const temp = [];
        assets.forEach( asset => {
            temp.push(new TicketAsset(asset));
        });
        return temp;
    }
}
