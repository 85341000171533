import { Routes } from '@angular/router';
import { ComingSoonComponent } from '../components/coming-soon/coming-soon.component';

// Route for content layout with sidebar, navbar and footer
export const FULL_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: './dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'administrator',
    loadChildren: './administrator/administrator.module#AdministratorModule'
  },
  {
    path: 'spot-type',
    loadChildren: './spot-type/spot-type.module#SpotTypeModule'
  },
  {
    path: 'hub',
    loadChildren: './hub/hub.module#HubModule'
  },
  {
    path: 'user',
    loadChildren: './user/user.module#UserModule'
  },
  {
    path: 'lock',
    loadChildren: './lock/lock.module#LockModule'
  },
  {
    path: 'medium',
    loadChildren: './medium/medium.module#MediumModule'
  },
  {
    path: 'bikeway',
    loadChildren: './bikeway/bikeway.module#BikewayModule'
  },
  {
    path: 'subscription',
    loadChildren: './subscription/subscription.module#SubscriptionModule'
  },
  {
    path: 'franchise',
    loadChildren: './franchise/franchise.module#FranchiseModule'
  },
  {
    path: 'penalty',
    loadChildren: './penalty/penalty.module#PenaltyModule'
  },
  {
    path: 'trip',
    loadChildren: './trip/trip.module#TripModule'
  },
  {
    path: 'ticket',
    loadChildren: './ticket/ticket.module#TicketModule'
  },
  {
    path: 'ticket-type',
    loadChildren: './ticket-type/ticket-type.module#TicketTypeModule'
  },
  {
    path: 'operation-time',
    loadChildren: './operation-time/operation-time.module#OperationTimeModule'
  },
  {
    path: 'setting',
    loadChildren: './setting/setting.module#SettingModule'
  },
  {
    path: 'sanction',
    loadChildren: './sanction/sanction.module#SanctionModule'
  },
  {
    path: 'replacement-part',
    loadChildren: './replacement-part/replacement-part.module#ReplacementPartModule'
  },
  {
    path: 'faq-category',
    loadChildren: './faq-category/faq-category.module#FaqCategoryModule'
  },
  {
    path: 'faq',
    loadChildren: './faq/faq.module#FaqModule'
  },
  {
    path: 'payment',
    loadChildren: './payment/payment.module#PaymentModule'
  },
  {
    path: 'notification',
    loadChildren: './notification/notification.module#NotificationModule'
  },
  {
    path: 'workshop',
    loadChildren: './workshop/workshop.module#WorkshopModule'
  },
  {
    path: 'workshop-reason',
    loadChildren: './workshop-reason/workshop-reason.module#WorkshopReasonModule'
  },
  {
    path: 'geofencing',
    loadChildren: './geofencing/geofencing.module#GeofencingModule'
  },
  {
    path: 'communication',
    loadChildren: './communication/communication.module#CommunicationModule'
  },
  {
    path: 'statistic',
    loadChildren: './statistic/statistic.module#StatisticModule'
  },
  {
    path: 'reward',
    loadChildren: './reward/reward.module#RewardModule'
  },
  {
    path: 'level',
    loadChildren: './level/level.module#LevelModule'
  },
  {
    path: 'free-use-date',
    loadChildren: './free-use-date/free-use-date.module#FreeUseDateModule'
  },
  {
    path: 'coming-soon/:path',
    component: ComingSoonComponent
  }
];
