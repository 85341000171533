import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { ApiService } from '../api/api.service';
import { EndpointsConstants } from '../../../helpers/endpoints';
import { BaseResponse } from 'src/app/models/base-response';
import { Lock } from 'src/app/models/lock';
import { generateParamsForRoutes } from 'src/app/helper/utils';
import { LockOffline } from 'src/app/models/lock-offline';
import { Observable } from 'rxjs/internal/Observable';
import { UploadedFile } from 'src/app/models/uploaded-file';
import { CatalogMimeTypes } from '../../../helpers/catalog';

@Injectable({
  providedIn: 'root'
})
export class LockService extends ApiService {

  constructor(httpService: HttpService) {
    super(httpService);
    this.baseUrl = EndpointsConstants.lock.base;
    this.logEntity = EndpointsConstants.lock.base;
  }

  getFree(): Observable<BaseResponse<Lock>> {
    const url = this.baseUrl + EndpointsConstants.lock.free;
    return this.httpService.get<Lock>(url, null, true);
  }

  getOffline(page: number = 0, pageSize: number = 0, filters: any = []): Observable<BaseResponse<LockOffline>> {
    const url = this.baseUrl + EndpointsConstants.lock.offline;
    const params = generateParamsForRoutes(filters, page, pageSize);
    return this.httpService.get<LockOffline>(url, params, true);
  }

  getStatuses(page: number = 0, pageSize: number = 0, filters: any = []): Observable<BaseResponse<Lock>> {
    const url = this.baseUrl + EndpointsConstants.lock.statuses;
    const params = generateParamsForRoutes(filters, page, pageSize);
    return this.httpService.get(url, params, true);
  }

  ring(imei: string, params) {
    const url = `${this.baseUrl}${EndpointsConstants.lock.command}${EndpointsConstants.lock.ring}/${imei}`;
    return this.httpService.get(url, params);
  }

  shutdown(imei: string) {
    const url = `${this.baseUrl}${EndpointsConstants.lock.command}${EndpointsConstants.lock.shutdown}/${imei}`;
    return this.httpService.get(url);
  }

  getPosition(imei: string) {
    const url = `${this.baseUrl}${EndpointsConstants.lock.command}${EndpointsConstants.lock.position}/${imei}`;
    return this.httpService.get(url);
  }

  getBateriesLevel() {
    const url = `${this.baseUrl}${EndpointsConstants.lock.batteriesLevel}`;
    return this.httpService.get(url);
  }

  bulkCreateByFile(file,): Observable<BaseResponse<UploadedFile>> {
    const url = `${this.baseUrl}${EndpointsConstants.lock.bulkCreateByFile}`;
    const formData: FormData = new FormData();
    formData.append('file', new Blob([file], { type: CatalogMimeTypes.csv }), file.name);
    return this.httpService.post(url, formData, true);
  }
}