import { LatestStatus } from './latest-status';
import { Log } from './log';
import { Heartbeat } from './heartbeat';
import { Medium } from './medium';
import { BaseEntity } from './base-entity';
import { Status } from './status';
import { LatestPosition } from './latest-position';

export class Lock extends BaseEntity<Lock> {
  id?: number;
  deviceCode?: string;
  imei: string;
  idStatus?: number;
  latestStatus?: LatestStatus;
  iccid: string;
  bluetoothAddress: string;
  createdAt?: Date;
  idLatestHeartbeat?: number;
  idLatestPosition?: number;
  idLatestStatus?: number;
  latestHeartbeat?: Heartbeat;
  medium?: Medium;
  latestConnection?: Date | string;
  latestPosition?: LatestPosition;
  lockStatuses?: {
    createdAt: Date,
    status: Status
  }[];
  manufacturedID: string;
  internalID: number;
  qr: string;

  constructor(lock?) {
    super(lock);
  }

  parse(e: any): Lock {
    const lock = new Lock(e);
    return lock;
  }

  populate(lock) {
    if (lock.id) { this.id = lock.id; }
    if (lock.deviceCode) { this.deviceCode = lock.deviceCode; }
    this.imei = lock.imei;
    // Status from form
    if (lock.idStatus) {
      this.idStatus = lock.idStatus;
    }
    // Status from api
    if (lock.latestStatus) {
      this.idStatus = lock.latestStatus.idStatus;
      this.latestStatus = new LatestStatus(lock.latestStatus);
    }
    this.iccid = lock.iccid;
    this.bluetoothAddress = lock.bluetoothAddress;
    if (lock.createdAt) { this.createdAt = new Date(lock.createdAt); }
    if (lock.idLatestHeartbeat) { this.idLatestHeartbeat = lock.idLatestHeartbeat; }
    if (lock.latestHeartbeat) { this.latestHeartbeat = new Heartbeat(lock.latestHeartbeat); }
    if (lock.idLatestPosition) { this.idLatestPosition = lock.idLatestPosition; }
    if (lock.idLatestStatus) { this.idLatestStatus = lock.idLatestStatus; }
    if (lock.medium) { this.medium = new Medium(lock.medium); }
    if (lock.latestConnection) { this.latestConnection = lock.latestConnection; }
    if (lock.latestPosition) {
      this.latestPosition = new LatestPosition(lock.latestPosition);
    }
    if (lock.lockStatuses) {
      this.lockStatuses = this.setLockStatuses(lock.lockStatuses);
    }
    this.manufacturedID = lock.manufacturedID;
    this.internalID = lock.internalID;
    this.qr = lock.qr;
  }

  setLockStatuses?(lockStatuses) {
    const temp = [];
    lockStatuses.forEach(lockStatus => {
      temp.push({
        createdAt: new Date(lockStatus.createdAt),
        status: new Status(lockStatus.status)
      });
    });
    return temp;
  }
}
