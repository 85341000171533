import { BaseEntity } from './base-entity';

export class HubSpot extends BaseEntity<HubSpot> {
  id?: number;
  quantity: number;
  idSpotType: number;
  idHub?: number;

  constructor(hubSpot?) {
    super(hubSpot);
  }

  parse(e: any): HubSpot {
    const hubSpot = new HubSpot(e);
    return hubSpot;
  }

  populate(hubSpot) {
    if (hubSpot.id) { this.id = hubSpot.id; }
    this.quantity = hubSpot.quantity;
    this.idSpotType = hubSpot.idSpotType;
    if (hubSpot.idHub) { this.idHub = hubSpot.idHub; }
  }
}
