import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as alertFunctions from 'src/app/core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss']
})
export class InputFileComponent implements OnInit {
  fileName: string;

  @Input() buttonText: string;
  @Input() btnStyleClasses = 'btn-primary text-bold-300';
  @Input() tooltipText: string;
  @Input() showTooltip = true;
  @Input() showUploadedFile = true;
  @Input() allowedMimeTypes?;
  @Input() showInput = true;
  @Input() showAlert = false;
  @Input() alertText: string;
  @Input() idInput = 'element';

  @Output() sendFile = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  openFileBrowser(event: any) {
    if (this.showAlert) {
      const data = {
        title: this.alertText,
        type: 'question'
      };
      alertFunctions.confirmAction(data).then( result => {
        if (result.value) {
          this.openUploader(event);
        }
      });
    } else {
      this.openUploader(event);
    }
  }

  openUploader(event) {
    event.preventDefault();
    const element: HTMLElement = document.getElementById(this.idInput) as HTMLElement;
    element.click();
  }

  onFileChange(event) {
    const file = event.target.files[0];
    this.fileName = file.name;
    this.sendFile.emit(event);
  }

}
