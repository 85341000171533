import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-card-dashboard',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardDashboardComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() footerLabels: object[] = [];
  @Input() tooltipContent: any;

  constructor() { }

  ngOnInit() {
  }

}
