import { MediumType } from './medium-type';
import { BaseEntity } from './base-entity';

export class ReplacementPart extends BaseEntity<ReplacementPart> {
    id?: number;
    idMediumType: number;
    mediumType?: MediumType;
    name: string;
    code: string;
    price: number;
    stock: number;
    url?: string;

    constructor(replacementPart?) {
      super(replacementPart);
    }

    parse(e: any): ReplacementPart {
      const replacementPart = new ReplacementPart(e);
      return replacementPart;
    }

    populate(replacementPart) {
      if (replacementPart.id) { this.id = replacementPart.id; }
      this.name = replacementPart.name;
      this.code = replacementPart.code;
      this.price = replacementPart.price;
      this.idMediumType = replacementPart.idMediumType;
      this.stock = replacementPart.stock;
      if (replacementPart.mediumType) { this.mediumType = new MediumType(replacementPart.mediumType); }
      if (replacementPart.url) { this.url = replacementPart.url; }
    }
}
