import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { ApiService } from '../api/api.service';
import { EndpointsConstants } from '../../../helpers/endpoints';
import { generateParamsForRoutes } from 'src/app/helper/utils';

@Injectable({
  providedIn: 'root'
})
export class ReplacementPartService extends ApiService {

  constructor(httpService: HttpService) {
    super(httpService);
    this.baseUrl = EndpointsConstants.replacementPart.base;
    this.logEntity = EndpointsConstants.replacementPart.log;
  }

  getLogsByOrder(id: number, filters: any = []) {
    const url = `${this.baseUrl}${EndpointsConstants.log.base}/${id}`;
    const params = generateParamsForRoutes(filters);
    return this.httpService.get(url, params, true);
  }
}
