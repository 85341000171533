import { Component, OnInit, OnChanges, Input, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { isNull } from 'src/app/helper/utils';

@Component({
  selector: 'app-text-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextEditorComponent implements OnInit, OnChanges {
  editor;

  @Input() editorFormGroup: FormGroup;
  @Input() editorFormControlName: string;
  @Input() showDefaultIcons = true;
  @Input() showIcons = true;
  @Input() relativeToTrip = false;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
  }

  onContentChanged(quill) {
  }

  onEditorCreated(quill) {
    this.editor = quill;
  }

  addLabel(label: string) {
    const cursorPosition = (!isNull(this.editor.getSelection())) ? this.editor.getSelection().index : 0;
    const addLabel = `{${label}}`;
    this.editor.insertText(cursorPosition, addLabel);

    // The text is selected from here so the format is not lost when a new variable is added
    const fullText = this.editor.root.innerHTML;

    this.editorFormGroup.get(this.editorFormControlName).patchValue(fullText);
    this.editor.setSelection(fullText.length);
  }
}
