import { FailureField } from './failure-field';
import { BaseEntity } from './base-entity';

export class TicketFailureField extends BaseEntity<TicketFailureField> {
    id: number;
    name: string;
    code: string;
    primaryType: string;
    primaryValue?: string;
    secondaryType: string;
    secondaryValue?: string;
    children?: FailureField[];

    constructor(ticketFailureField?) {
        super(ticketFailureField);
    }

    parse(e: any): TicketFailureField {
        const ticketFailureField = new TicketFailureField(e);
        return ticketFailureField;
    }

    populate(ticketFailureField) {
        this.id = ticketFailureField.id;
        this.name = ticketFailureField.name;
        this.code = ticketFailureField.code;
        this.primaryType = ticketFailureField.primaryType;
        if (ticketFailureField.primaryValue) { this.primaryValue = ticketFailureField.primaryValue; }
        this.secondaryType = ticketFailureField.secondaryType;
        if (ticketFailureField.secondaryValue) { this.secondaryValue = ticketFailureField.secondaryValue; }
        if (ticketFailureField.children) { this.children = this.setFailureField(ticketFailureField.children); }
    }

    setFailureField?(failureFields) {
        const temp = [];
        failureFields.forEach( field => {
            temp.push(new FailureField(field));
        });
        return temp;
    }
}
