import { BaseEntity } from './base-entity';
import { Status } from './status';
import { Trip } from './trip';
import { Administrator } from './administrator';
import { User } from './user';

export class LatestStatus extends BaseEntity<LatestStatus> {
  id: number;
  createdAt?: Date;
  comment?: string;
  idMedium?: number;
  idStatus: number;
  status?: Status;
  trip?: Trip;
  admin?: Administrator;
  user?: User;

  constructor(latestStatus?) {
    super(latestStatus);
  }

  parse(e: any): LatestStatus {
    const latestStatus = new LatestStatus(e);
    return latestStatus;
  }

  populate(latestStatus) {
    this.id = latestStatus.id;
    if (latestStatus.comment) { this.comment = latestStatus.comment; }
    if (latestStatus.createdAt) { this.createdAt = new Date(latestStatus.createdAt); }
    this.idStatus = latestStatus.idStatus;
    this.idMedium = latestStatus.idMedium;
    if (latestStatus.status) { this.status = new Status(latestStatus.status); }
    if (latestStatus.trip) { this.trip = new Trip(latestStatus.trip); }
    if (latestStatus.admin) { this.admin = new Administrator(latestStatus.admin); }
    if (latestStatus.user) { this.user = new User(latestStatus.user); }
  }
}
