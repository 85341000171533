import { Trip } from './trip';
import { User } from './user';
import { LatestStatus } from './latest-status';
import { AdditionalUse } from './additional-use';
import { BaseEntity } from './base-entity';
import { CatalogAdditionalChargeIdStatuses } from 'projects/admin/src/app/helpers/catalog';
import { Log } from './log';

export class AdditionalCharge extends BaseEntity<AdditionalCharge> {
    id: number;
    amount: number;
    createdAt: Date;
    expirationDate: Date;
    paymentDate?: Date;
    comment: string;
    idAdditionalUse: number;
    additionalUse: AdditionalUse;
    idTrip: number;
    trip: Trip;
    idUser: number;
    user: User;
    idLatestStatus: number;
    latestStatus: LatestStatus;
    hideActions: string[];

    constructor(additionalCharge?) {
        super(additionalCharge);
    }

    parse(e: any): AdditionalCharge {
        const additionalCharge = new AdditionalCharge(e);
        return additionalCharge;
    }

    populate(additionalCharge) {
        if (additionalCharge.id) { this.id = additionalCharge.id; }
        this.createdAt = new Date(additionalCharge.createdAt);
        this.amount = additionalCharge.amount;
        this.expirationDate = additionalCharge.expirationDate;
        if (additionalCharge.paymentDate) { this.paymentDate = new Date(additionalCharge.paymentDate); }
        this.comment = additionalCharge.comment;
        this.idAdditionalUse = additionalCharge.idAdditionalUse;
        if (additionalCharge.additionalUse) { this.additionalUse = new AdditionalUse(additionalCharge.additionalUse); }
        this.idTrip = additionalCharge.idTrip;
        if (additionalCharge.trip) { this.trip = new Trip(additionalCharge.trip); }
        this.idUser = additionalCharge.idUser;
        if (additionalCharge.user) { this.user = new User(additionalCharge.user); }
        this.idLatestStatus = additionalCharge.idLatestStatus;
        if (additionalCharge.latestStatus) { this.latestStatus = new LatestStatus(additionalCharge.latestStatus); }
        this.hideActions = this.setHideActions();
    }

    setHideActions?() {
        const items = [];
        if (this.latestStatus && this.latestStatus.status.id !== CatalogAdditionalChargeIdStatuses.review) {
            items.push('editWithEvent');
            items.push('charge');
            items.push('reject');
        }
        return items;
    }
}
