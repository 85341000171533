import { Component, OnInit, Input, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ringAnimation } from 'src/app/shared/animations/animations';
import { LockService } from '../../../core-admin/services/lock/lock.service';
import * as alertFunctions from 'src/app/core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-ring-lock',
  templateUrl: './ring-lock.component.html',
  styleUrls: ['./ring-lock.component.scss'],
  animations: [
    ringAnimation
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RingLockComponent implements OnInit {
  state = 'off';

  @Input() imei: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private lockService: LockService
  ) { }

  ngOnInit() {
  }

  ringLock() {
    const data = {
      title: '¿Estás seguro que deseas sonar la alarma?',
      type: 'question'
    };
    alertFunctions.confirmAction(data).then( result => {
      if (result.value) {
        const params = {
          time: 45
        };
        this.lockService.ring(this.imei, params).subscribe( _ => {
          this.state = 'on';
          this.animateRing();
        });
      }
    });
  }

  animateRing() {
    const intervalID = setInterval( _ => {
      this.state = (this.state === 'off') ? 'on' : 'off';
      this.cdr.detectChanges();
    }, 1500);
    setTimeout( _ => {
      clearInterval(intervalID);
      this.state = 'off';
      this.cdr.detectChanges();
    }, 10000);
  }
}
