import { BaseEntity } from './base-entity';

export class Failure extends BaseEntity<Failure> {
    id: number;
    name: string;
    code: string;

    constructor(failure?) {
        super(failure);
    }

    parse(e: any): Failure {
        const failure = new Failure(e);
        return failure;
    }

    populate(failure) {
        this.id = failure.id;
        this.name = failure.name;
        this.code = failure.code;
    }
}
