import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { isNullOrEmptyOrUndefined, isEmpty } from 'src/app/helper/utils';
import * as moment from 'moment';

@Component({
  selector: 'app-input-hour',
  templateUrl: './input-hour.component.html',
  styleUrls: ['./input-hour.component.scss']
})
export class InputHourComponent implements OnInit, OnChanges {
  modelTime = {
    hour: 0,
    minute: 0
  };

  @Input() control: FormControl;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    // tslint:disable-next-line:max-line-length
    if (simpleChanges.control) {
      this.patchTime();
    }
  }

  patchTime() {
    const time = this.control.value.split(':');
    if (time.length) {
      // Use + to convert to number
      this.modelTime = {
        hour: +time[0],
        minute: +time[1]
      };
    }
  }

  onTimeChange() {
    if (isNullOrEmptyOrUndefined(this.modelTime)) {
      this.control.setValue('');
      this.control.markAsTouched();
    } else {
      const time = `${this.pad(this.modelTime.hour)}:${this.pad(this.modelTime.minute)}`;
      this.control.setValue(time);
    }
  }

  private pad(i: number): string {
    i = (isNaN(i) || !Number.isInteger(i)) ? 0 : i;
    return i < 10 ? `0${i}` : `${i}`;
  }
}
