import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { ApiService } from '../api/api.service';
import { EndpointsConstants } from '../../../helpers/endpoints';

@Injectable({
  providedIn: 'root'
})
export class UserService extends ApiService {

  constructor(httpService: HttpService) {
    super(httpService);
    this.baseUrl = EndpointsConstants.user.base;
    this.logEntity = EndpointsConstants.user.base;
  }

  updateStatus(obj: any, id: number) {
    const url = `${this.baseUrl}${EndpointsConstants.user.status}/${id}`;
    return this.httpService.put(url, obj, true);
  }

  validateEmail(id: number) {
    const url = `${this.baseUrl}${EndpointsConstants.user.validEmail}/${id}`;
    return this.httpService.post(url, {}, true);
  }

  validateDocumentation(id: number) {
    const url = `${this.baseUrl}${EndpointsConstants.user.validDocument}/${id}`;
    return this.httpService.post(url, {}, true);
  }
}
