import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { HttpService } from '../http/http.service';
import { EndpointsConstants } from '../../../helpers/endpoints';
import { Administrator } from 'src/app/models/administrator';
import { setLocalData, getLocalData, removeLocalData } from 'src/app/helper/storage';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userSource: BehaviorSubject<Administrator>;

  loggedUser$: Observable<Administrator>;
  loggedUser: Administrator;

  constructor(private router: Router,
              private toastr: ToastService,
              private httpService: HttpService) {

    this.getLoggedUser();
    this.userSource = new BehaviorSubject<Administrator>(this.loggedUser);
    this.loggedUser$ = this.userSource.asObservable();
  }

  login(email: string, password: string): Observable<boolean> {
    const url = EndpointsConstants.auth.base + EndpointsConstants.auth.login;

    const params = {
      email,
      password,
      requestFromWebAdmin: true
    };

    return new Observable<boolean>(observer => {
      this.httpService.post(url, params, true).subscribe(
        response => {
          this.setLoginData(response);
          observer.next(true);
        });
    });
  }

  setLoginData(response = null) {
    const token = response.data.token;
    const user = response.data.admin;

    setLocalData('smod-admin-currentUser', user);
    setLocalData('smod-admin-token', token);

    this.userSource.next(new Administrator(user));
  }

  getLoggedUser() {
    const user = getLocalData('smod-admin-currentUser');

    if (user) {
      this.loggedUser = new Administrator({
        id: user.id,
        lastName: user.lastName,
        firstName: user.firstName,
        avatar: user.avatar,
        email: user.email,
        profiles: user.profiles,
        sex: user.sex,
        position: user.position
      });
      if (this.userSource) { this.userSource.next(this.loggedUser); }
    }
  }

  logout(): void {
    removeLocalData('smod-admin-currentUser');
    removeLocalData('smod-admin-token');
    this.router.navigate(['/login']);
  }

  isAuthenticated(redirect: boolean = true): boolean {
    const currentUser = getLocalData('smod-admin-currentUser');
    if (!currentUser) {
      if (redirect) {
        this.router.navigate(['/login']);
      }
      return false;
    }
    return true;
  }

  forgotPassword(email: string) {
    const url = EndpointsConstants.auth.base + EndpointsConstants.auth.forgotPassword;

    return this.httpService.post(url, { email });
  }

  restorePassword(password: string, token: string) {
    const url = EndpointsConstants.auth.base + EndpointsConstants.auth.resetPassword;
    const params: any = {
      newPassword: password,
      token
    };
    return this.httpService.post(url, params);
  }

}
