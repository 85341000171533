import { BaseEntity } from './base-entity';

export class Heartbeat extends BaseEntity<Heartbeat> {
    id: number;
    batteryVolume: number;
    GSMSignal: number;
    lockStatus: boolean;

    constructor(heartbeat?) {
        super(heartbeat);
    }

    parse(e: any): Heartbeat {
        const heartbeat = new Heartbeat(e);
        return heartbeat;
    }

    populate(heartbeat) {
        this.id = heartbeat.id;
        this.batteryVolume = heartbeat.batteryVolume;
        this.GSMSignal = heartbeat.GSMSignal;
        this.lockStatus = (+heartbeat.lockStatus === 0) ? false : true;
    }
}
