import { TicketAsset } from './ticket-asset';
import { Ticket } from './ticket';
import { BaseEntity } from './base-entity';

export class TicketComment extends BaseEntity<TicketComment> {
    id?: number;
    comment: string;
    automatic?: boolean;
    idUser?: number;
    idAdmin?: number;
    ticketAsset?: TicketAsset[];
    idTicket?: number;
    ticket?: Ticket;

    constructor(ticketComment?) {
        super(ticketComment);
    }

    parse(e: any): TicketComment {
        const ticketComment = new TicketComment(e);
        return ticketComment;
    }

    populate(ticketComment) {
        if (ticketComment.id) { this.id = ticketComment.id; }
        this.comment = ticketComment.comment;
        if (ticketComment.automatic) { this.automatic = ticketComment.automatic; }
        if (ticketComment.idUser) { this.idUser = ticketComment.idUser; }
        if (ticketComment.idAdmin) { this.idAdmin = ticketComment.idAdmin; }
        if (ticketComment.ticketAsset) { this.setAssets(ticketComment.ticketAsset); }
        if (ticketComment.idTicket) { this.idTicket = ticketComment.idTicket; }
        if (ticketComment.ticket) { this.ticket = new Ticket(ticketComment.ticket); }
    }

    setAssets?(assets) {
        const temp = [];
        assets.forEach( asset => {
            temp.push(new TicketAsset(asset));
        });
        return temp;
    }
}
