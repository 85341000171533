import { AssetType } from './asset-type';
import { Ticket } from './ticket';
import { TicketComment } from './ticket-comment';
import { BaseEntity } from './base-entity';
import { getClassToCropImage } from 'projects/admin/src/app/helpers/utils-admin';

export class TicketAsset extends BaseEntity<TicketAsset> {
    id?: number;
    url: string;
    classCropImage: string;
    idTicket?: number;
    idTicketComment?: number;
    idAssetType: number;
    assetType?: AssetType;
    ticket?: Ticket;
    ticketComment?: TicketComment;

    constructor(ticketAsset?) {
        super(ticketAsset);
    }

    parse(e: any): TicketAsset {
        const ticketAsset = new TicketAsset(e);
        return ticketAsset;
    }

    populate(ticketAsset) {
        if (ticketAsset.id) { this.id = ticketAsset.id; }
        this.url = ticketAsset.url;
        this.classCropImage = getClassToCropImage(ticketAsset.url);
        if (ticketAsset.idTicket) { this.idTicket = ticketAsset.idTicket; }
        if (ticketAsset.idTicketComment) { this.idTicketComment = ticketAsset.idTicketComment; }
        this.idAssetType = ticketAsset.idAssetType;
        if (ticketAsset.assetType) { this.assetType = new AssetType(ticketAsset.assetType); }
        if (ticketAsset.ticket) { this.ticket = new Ticket(ticketAsset.ticket); }
        if (ticketAsset.ticketComment) { this.ticketComment = new TicketComment(ticketAsset.ticketComment); }
    }
}
