import { Injectable } from '@angular/core';
import { EndpointsConstants } from '../../../helpers/endpoints';
import { HttpService } from '../http/http.service';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class SanctionService extends ApiService {

  constructor(
    httpService: HttpService
  ) {
    super(httpService);
    this.baseUrl = EndpointsConstants.sanction.base;
    this.logEntity = EndpointsConstants.sanction.base;
  }
}
