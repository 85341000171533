import { Component, AfterViewChecked, OnInit, ViewChild, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../../../core-admin/services/auth/auth.service';
import { Administrator } from 'src/app/models/administrator';
import { getTimezone, isNullOrEmptyOrUndefined, isUndefined } from 'src/app/helper/utils';
import { NotificationService } from '../../../core-admin/services/notification/notification.service';
import { SocketService } from '../../../core-admin/services/socket/socket.service';
import { EndpointsConstants } from '../../../helpers/endpoints';
import { Notification } from 'src/app/models/notification';
import { Router } from '@angular/router';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { PushNotificationOptions, PushNotificationService } from 'ngx-push-notifications';
import { IconNotification } from '../../../helpers/config';
import { BaseResponse } from 'src/app/models/base-response';
import { CatalogSocketType } from '../../../helpers/catalog';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements AfterViewChecked, OnInit, OnDestroy {
    timeZone = getTimezone();
    toggleClass = 'ft-maximize';
    placement = 'bottom-right';
    user: Administrator;
    notReadedNotifications: number;
    notifications: BaseResponse<Notification>;
    enableClickOutside = false;
    public isCollapsed = true;
    getNotificationsBySocketSubscription: Subscription;

    @ViewChild('notificationsDropdown') notificationsDropdown: NgbDropdown;

    constructor(
        private cdr: ChangeDetectorRef,
        private router: Router,
        private authService: AuthService,
        private notificationService: NotificationService,
        private socketService: SocketService,
        private pushNotificationService: PushNotificationService
    ) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => {
        return false;
      };
    }

    ngOnInit() {
        this.pushNotificationService.requestPermission().subscribe();
        this.getNotificationsBySocket();
        this.getLoggedUser();
        this.subscribeReadNotificationId();
        this.subscribeNotReadedNotifications();
        this.getNotReadedNotifications();
        this.getNotifications();
    }

    ngAfterViewChecked() {
        setTimeout(() => {
            const wrapperDiv = document.getElementsByClassName('wrapper')[0];
            const dir = wrapperDiv.getAttribute('dir');
            if (dir === 'rtl') {
                this.placement = 'bottom-left';
            } else if (dir === 'ltr') {
                this.placement = 'bottom-right';
            }
            this.cdr.detectChanges();
        }, 3000);
    }

    getLoggedUser() {
        this.authService.loggedUser$.subscribe(user => {
            if (!isNullOrEmptyOrUndefined(user)) { this.user = user; }
            this.cdr.detectChanges();
        });
    }

    subscribeReadNotificationId() {
        this.notificationService.readNotificationId$.subscribe( id => {
            this.dismissNotificationById(id);
        });
    }

    subscribeNotReadedNotifications() {
      this.notificationService.notReadedNotifications$.subscribe( notReadedNotifications => {
        this.notReadedNotifications = notReadedNotifications;
        this.cdr.detectChanges();
      });
    }

    getNotReadedNotifications() {
      const filters = {
        wasRead: false
      };
      this.notificationService.get(1, EndpointsConstants.shortRequestLimit, filters).subscribe( response => {
        this.notificationService.emitNotReadedNotifications(response.data['metadata'].totalRegistries);
      });
    }

    getNotifications() {
        const filters = {
            order: 'id,DESC'
        };
        this.notificationService.get(1, EndpointsConstants.shortRequestLimit, filters).subscribe( response => {
            this.notifications = new BaseResponse<Notification>(response, Notification);
            this.cdr.detectChanges();
        });
    }

    getNotificationsBySocket() {
        this.getNotificationsBySocketSubscription = this.socketService.getEventBySocket(CatalogSocketType.pushAdmin).subscribe( notification => {
            this.notifications.data['rows'].unshift(notification);
            const isGranted = this.pushNotificationService.isPermissionGranted;
            if (isGranted) {
                const options = new PushNotificationOptions();
                options.icon = IconNotification;
                options.body = notification.message;
                this.pushNotificationService.create(notification.title, options).subscribe( response => {
                    if (response.event.type === 'show') {
                        setTimeout(() => {
                            response.notification.close();
                        }, 3000);
                    }
                    if (response.event.type === 'click') {
                        this.markNotificationAsRead(notification);
                        response.notification.close();
                    }
                });
            }
            this.notificationService.emitNotReadedNotifications(this.notReadedNotifications + 1);
            this.cdr.detectChanges();
        });
    }

    onToggleNotificationDrodpown(isOpen) {
        this.enableClickOutside = (isOpen) ? true : false;
    }

    onClickOutsideNotifications() {
        if (this.notReadedNotifications) {
            this.notificationService.markAll().subscribe( _ => {
                this.notificationService.emitNotReadedNotifications(0);
                this.notifications.data['rows'].map( notification => {
                    notification.wasRead = true;
                    return notification;
                });
                this.cdr.detectChanges();
            });
        }
    }

    goToUrlNotification(notification: Notification) {
      this.notificationsDropdown.toggle();
      if (notification.wasRead) {
        this.router.navigate([notification.url]);
      } else {
        this.markNotificationAsRead(notification);
      }
    }

    markNotificationAsRead(notification: Notification) {
        this.notificationService.markOne(notification.id).subscribe( _ => {
            this.notificationService.emitNotReadedNotifications(this.notReadedNotifications - 1);
            this.dismissNotificationById(notification.id);
            this.router.navigate([notification.url]);
            this.cdr.detectChanges();
        });
    }

    dismissNotificationById(id: number) {
        if (!isUndefined(this.notifications)) {
            const index = this.notifications.data['rows'].findIndex( item => item.id === id);
            if (index > -1) { this.notifications.data['rows'][index].wasRead = true; }
            this.cdr.detectChanges();
        }
    }

    ToggleClass() {
        if (this.toggleClass === 'ft-maximize') {
            this.toggleClass = 'ft-minimize';
        } else {
            this.toggleClass = 'ft-maximize';
        }
        this.cdr.detectChanges();
    }

    clickLogout() {
        this.authService.logout();
    }

    ngOnDestroy() {
        if (this.getNotificationsBySocketSubscription) { this.getNotificationsBySocketSubscription.unsubscribe(); }
    }
}
