import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NotificationService } from '../../../core-admin/services/notification/notification.service';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { Notification } from 'src/app/models/notification';
import { CatalogAlertTypes, CatalogSeverity, CatalogSocketType } from '../../../helpers/catalog';
import { Router } from '@angular/router';
import { BaseResponse } from 'src/app/models/base-response';
import { EndpointsConstants } from '../../../helpers/endpoints';
import * as alertFunctions from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { SocketService } from '../../../core-admin/services/socket/socket.service';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-slider-notification',
  templateUrl: './slider-notification.component.html',
  styleUrls: ['./slider-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderNotificationComponent implements OnInit, OnDestroy {
  getNotificationsBySocketSubscription: Subscription;
  notifications: Notification[] = [];
  showCarousel = false;
  notReadedNotifications: number;

  @ViewChild('carousel') carousel: NgbCarousel;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private notificationService: NotificationService,
    private socketService: SocketService
  ) { }

  ngOnInit() {
    this.subscribeNotReadedNotifications();
    this.getNotReadedNotifications();
    this.getNotificationsBySocket();
  }

  getNotReadedNotifications() {
    const filters = {
        wasRead: false,
        severity: CatalogSeverity.alert
    };
    this.notificationService.get(1, EndpointsConstants.shortRequestLimit, filters).subscribe( response => {
      const notifications = new BaseResponse<Notification>(response, Notification);
      this.notifications = notifications.data['rows'];
      if (response.data['rows'].length) {
        this.startCarousel();
      }
      this.cdr.detectChanges();
    });
  }

  getNotificationsBySocket() {
    this.getNotificationsBySocketSubscription = this.socketService.getEventBySocket(CatalogSocketType.pushAdmin).subscribe( notification => {
      if (notification.severity === CatalogSeverity.alert) {
        this.notifications.push(new Notification(notification));
        this.startCarousel();
      }
      this.cdr.detectChanges();
    });
  }

  startCarousel() {
    setTimeout( _ => {
      this.showCarousel = true;
      this.carousel.cycle();
      this.cdr.detectChanges();
    }, 100);
  }

  subscribeNotReadedNotifications() {
    this.notificationService.notReadedNotifications$.subscribe( notReadedNotifications => {
      this.notReadedNotifications = notReadedNotifications;
      this.cdr.detectChanges();
    });
  }

  goToNotification(notification: Notification) {
    this.markNotificationAsRead(notification);
    this.router.navigate([notification.url]);
    if (this.isMediumAlert(notification)) {
      alertFunctions.confirmSuccess(notification.title, notification.message, 'warning');
    }
  }

  markNotificationAsRead(notification: Notification) {
    this.notificationService.markOne(notification.id).subscribe( _ => {
      this.notificationService.emitReadNotificationId(notification.id);
      this.notificationService.emitNotReadedNotifications(this.notReadedNotifications - 1);
    });
  }

  isMediumAlert(notification: Notification) {
    return notification.getType() === CatalogAlertTypes.medium ? true : false;
  }

  ngOnDestroy() {
    if (this.getNotificationsBySocketSubscription) { this.getNotificationsBySocketSubscription.unsubscribe(); }
  }
}
