import { BaseEntity } from './base-entity';
import { Status } from './status';
import { Administrator } from './administrator';

export class TripStatus extends BaseEntity<TripStatus> {
  id: number;
  createdAt?: Date;
  idTrip: number;
  idStatus: number;
  idAdmin: number;
  status: Status;
  admin: Administrator;

  constructor(tripStatus?) {
    super(tripStatus);
  }

  parse(e: any): TripStatus {
    const tripStatus = new TripStatus(e);
    return tripStatus;
  }

  populate(tripStatus) {
    this.id = tripStatus.id;
    if (tripStatus.createdAt) { this.createdAt = new Date(tripStatus.createdAt); }
    this.idTrip = tripStatus.idTrip;
    this.idStatus = tripStatus.idStatus;
    this.idAdmin = tripStatus.idAdmin;
    this.status = new Status(tripStatus.status);
    this.admin = new Administrator(tripStatus.admin);
  }
}
