import { BaseEntity } from './base-entity';

export class FailureField extends BaseEntity<FailureField> {
    id: number;
    name: string;
    code: string;
    primaryType: string;
    secondaryType: string;
    primaryValue: string;
    secondaryValue: string;

    constructor(failureField?) {
        super(failureField);
    }

    parse(e: any): FailureField {
        const failureField = new FailureField(e);
        return failureField;
    }

    populate(failureField) {
        this.id = failureField.id;
        this.name = failureField.name;
        this.code = failureField.code;
        this.primaryType = failureField.primaryType;
        this.secondaryType = failureField.secondaryType;
        this.primaryValue = failureField.primaryValue;
        this.secondaryValue = failureField.secondaryValue;
    }
}
