import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { AuthService } from '../../../core-admin/services/auth/auth.service';
import { Administrator } from 'src/app/models/administrator';

declare var $: any;
@Component({
    // moduleId: module.id,
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    currentAdmin: Administrator;

    constructor(
        private authService: AuthService
    ) { }

    ngOnInit() {
        $.getScript('./assets/js/app-sidebar.js');
        this.getLoggedAdmin();
    }

    getLoggedAdmin() {
        this.authService.loggedUser$.subscribe( admin => {
            this.currentAdmin = new Administrator(admin);
            this.getValidRoutes();
        });
    }

    getValidRoutes() {
        const routes = JSON.parse(JSON.stringify(ROUTES));
        this.menuItems = [];
        routes.forEach( item => {
            // Validate roles in menu
            const isValid = (item.roles) ? this.validateRoutesByProfile(item.roles) : true;
            if (isValid) {
                const menu = {
                    ...item
                };
                menu.submenu = [];
                // Validate roles in submenu
                item.submenu.forEach( subItem => {
                    const isSubmenuValid = (subItem.roles) ? this.validateRoutesByProfile(subItem.roles) : true;
                    if (isSubmenuValid) {
                        menu.submenu.push(subItem);
                    }
                });
                this.menuItems.push(menu);
            }
        });
    }

    validateRoutesByProfile(roles) {
        let isValid = false;
        this.currentAdmin.profiles.forEach( profile => {
            if (roles.indexOf(profile) > -1) {
                isValid = true;
                return;
            }
        });
        return isValid;
    }
}
