import { BaseEntity } from './base-entity';
import { Administrator } from './administrator';
import { LatestStatus } from './latest-status';
import { WorkshopReason } from './workshop-reason';
import { ReplacementPart } from './replacement-part';
import { Medium } from './medium';

export class Workshop extends BaseEntity<Workshop> {
  id?: number;
  comment: string;
  idResponsable: number;
  responsable?: Administrator;
  idOperator: number;
  operator?: Administrator;
  idStatus: number;
  idLatestStatus: number;
  latestStatus?: LatestStatus;
  idMedium: number;
  workshopMedium?: Medium;
  workshopReason: WorkshopReason[];
  replacementParts: any[];
  workshopReplacementPart: ReplacementPart[];

  constructor(admin?) {
    super(admin);
  }

  parse(e: any): Workshop {
    const workshop = new Workshop(e);
    return workshop;
  }

  populate(workshop) {
    if (workshop.id) { this.id = workshop.id; }
    this.comment = workshop.comment;
    this.idResponsable = workshop.idResponsable;
    if (workshop.responsable) { this.responsable = new Administrator(workshop.responsable); }
    this.idOperator = workshop.idOperator;
    if (workshop.operator) { this.operator = new Administrator(workshop.operator); }
    if (workshop.latestStatus) { this.idStatus = workshop.latestStatus.idStatus; }
    this.idLatestStatus = workshop.idLatestStatus;
    if (workshop.latestStatus) { this.latestStatus = new LatestStatus(workshop.latestStatus); }
    this.idMedium = workshop.idMedium;
    if (workshop.workshopMedium) { this.workshopMedium = new Medium(workshop.workshopMedium); }
    if (workshop.workshopReplacementPart) { this.workshopReplacementPart = this.setWorkshopReplacementParts(workshop.workshopReplacementPart); }
    if (workshop.workshopReason) { this.workshopReason = this.setWorkshopReasons(workshop.workshopReason); }
    this.replacementParts = workshop.replacementParts;
  }

  setWorkshopReplacementParts?(workshopReplacementParts) {
    const temp = [];
    workshopReplacementParts.forEach(replacementPart => {
      temp.push(new ReplacementPart(replacementPart));
    });
    return temp;
  }

  setWorkshopReasons?(workshopReasons) {
    const temp = [];
    workshopReasons.forEach(workshopReason => {
      temp.push(new WorkshopReason(workshopReason));
    });
    return temp;
  }
}
