import { Injectable } from '@angular/core';
import { EndpointsConstants } from '../../../helpers/endpoints';
import { HttpService } from '../http/http.service';
import { ApiService } from '../api/api.service';
import { TicketComment } from 'src/app/models/ticket-comment';
import { BaseResponse } from 'src/app/models/base-response';
import { generateParamsForRoutes } from 'src/app/helper/utils';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class TicketService extends ApiService {

  constructor(httpService: HttpService) {
    super(httpService);
    this.baseUrl = EndpointsConstants.ticket.base;
  }

  getComments(page: number = 0, pageSize: number = 0, filters: any = []) {
    const url = this.baseUrl + EndpointsConstants.ticket.comment;
    const params = generateParamsForRoutes(filters, page, pageSize);
    return this.httpService.get(url, params, true);
  }

  getUnpaid(id: number) {
    const url = this.baseUrl + EndpointsConstants.ticket.unpaid + '/' + id;
    return this.httpService.get(url, null, true);
  }

  reply(ticketComment: TicketComment, idTicket): Observable<BaseResponse<TicketComment>> {
    const url = this.baseUrl + EndpointsConstants.ticket.reply + '/' + idTicket;
    return this.httpService.post<TicketComment>(url, ticketComment, true);
  }
}
