// Modules
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ImageCropperModule } from 'ng2-img-cropper';
import { TextEditorModule } from './components/text-editor/text-editor.module';
import { InputFileModule } from 'src/app/shared/input-file/input-file.module';
import { ClickOutsideModule } from 'ng-click-outside';

// Components
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ToggleFullscreenDirective } from './directives/toggle-fullscreen.directive';
import { BackgroundNavbarComponent } from './components/navbar/background/background.component';
import { SmodSidebarComponent } from './components/sidebar/smod/smod.component';
import { LogoSidebarComponent } from './components/sidebar/logo/logo.component';
import { BackgroundSidebarComponent } from './components/sidebar/background/background.component';
import { AvatarUserComponent } from './components/avatar-user/avatar-user.component';
import { ChangeLogComponent } from './components/change-log/change-log.component';
import { SliderNotificationComponent } from './components/slider-notification/slider-notification.component';
import { ModalFormSanctionComponent } from './components/modal-form-sanction/modal-form-sanction.component';
import { ModalFormTicketComponent } from './components/modal-form-ticket/modal-form-ticket.component';
import { ModalPreviewImageComponent } from './components/modal-preview-image/modal-preview-image.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { RingLockComponent } from './components/ring-lock/ring-lock.component';
import { ShutdownLockComponent } from './components/shutdown-lock/shutdown-lock.component';
import { HeaderDetailLockComponent } from './components/header-detail-lock/header-detail-lock.component';
import { ChangeTripStatusComponent } from './components/change-trip-status/change-trip-status.component';
import { TicketChatComponent } from './components/ticket-chat/ticket-chat.component';
import { ModalFormWorkshopOrderComponent } from './components/modal-form-workshop-order/modal-form-workshop-order.component';

// Services
import { PushNotificationService } from 'ngx-push-notifications';
import { FilterPipe } from './pipes/filter.pipe';
import { InputDateTimeComponent } from './components/input-date-time/input-date-time.component';
import { InfoTooltipComponent } from './components/tooltip/info-tooltip.component';
import { InputHourComponent } from './components/input-hour/input-hour.component';
import { CardDashboardComponent } from './components/card/card.component';

@NgModule({
  exports: [
    SharedModule,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    AvatarUserComponent,
    ChangeLogComponent,
    ChangeTripStatusComponent,
    SliderNotificationComponent,
    ToggleFullscreenDirective,
    FormsModule,
    ReactiveFormsModule,
    UiSwitchModule,
    NgSelectModule,
    ImageCropperModule,
    TextEditorModule,
    InputFileModule,
    ModalFormSanctionComponent,
    RingLockComponent,
    ShutdownLockComponent,
    HeaderDetailLockComponent,
    TicketChatComponent,
    FilterPipe,
    InputDateTimeComponent,
    InputHourComponent,
    InfoTooltipComponent,
    CardDashboardComponent
  ],
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    UiSwitchModule,
    NgSelectModule,
    ImageCropperModule,
    NgbModule,
    TextEditorModule,
    InputFileModule,
    ClickOutsideModule
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ToggleFullscreenDirective,
    BackgroundNavbarComponent,
    SmodSidebarComponent,
    LogoSidebarComponent,
    BackgroundSidebarComponent,
    AvatarUserComponent,
    ChangeLogComponent,
    SliderNotificationComponent,
    ModalFormSanctionComponent,
    ModalFormTicketComponent,
    ModalPreviewImageComponent,
    ComingSoonComponent,
    RingLockComponent,
    ShutdownLockComponent,
    HeaderDetailLockComponent,
    TicketChatComponent,
    ModalFormWorkshopOrderComponent,
    FilterPipe,
    InputDateTimeComponent,
    InputHourComponent,
    InfoTooltipComponent,
    ChangeTripStatusComponent,
    CardDashboardComponent
  ],
  providers: [
    PushNotificationService
  ],
  entryComponents: [
    ModalFormWorkshopOrderComponent
  ]
})
export class SharedAdminModule { }
