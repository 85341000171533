
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import * as $ from 'jquery';
import { CoreAdminModule } from './core-admin/core-admin.module';
import { CoreModule } from 'src/app/core/core.module';
import { AuthGuard } from './core-admin/services/auth-guard/auth-guard.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestInterceptor } from './http-interceptors/http-interceptor';
import { LoaderModule } from 'src/app/shared/components/loader/loader.module';

import { registerLocaleData } from '@angular/common';
import localeEsAr from '@angular/common/locales/es-AR';

registerLocaleData(localeEsAr);

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        CoreAdminModule,
        CoreModule,
        LoaderModule
    ],
    providers: [
        AuthGuard,
        { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: 'es-AR' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
