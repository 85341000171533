import { Component, Input, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { Administrator } from 'src/app/models/administrator';

@Component({
  selector: 'app-avatar-user',
  templateUrl: './avatar-user.component.html',
  styleUrls: ['./avatar-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarUserComponent {
  @Input() currentAdmin: Administrator;

  constructor() { }

}
