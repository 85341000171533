export const MapLatitudeCenter = -34.353249;

export const MapLongitudeCenter = -58.7384947;

export const MapZoom = 15;

export const TicketAssetsMaxFiles = 3;

export const PauseTime = 600;

export const IconNotification = './assets/img/smod-logo-collapsed-secondary.png';

export const DefaultRefreshMapTime = 30;

export const SecondaryColor = '#303967';

export const ClientPreffix = 'PE';
