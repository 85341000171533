import { Point } from './point';
import { Polygon } from './polygon';
import { HubSpot } from './hub-spot';
import { Medium } from './medium';
import { Status } from './status';
import { BaseEntity } from './base-entity';
import { CatalogHubPriorityLabel } from 'projects/admin/src/app/helpers/catalog';

export class Hub extends BaseEntity<Hub> {
  id?: number;
  active?: boolean;
  test: boolean;
  name: string;
  code: string;
  address: string;
  location?: Point;
  area?: Polygon;
  hubSpots?: HubSpot[];
  spotTypes?: string;
  freeSpots?: number;
  totalSpots?: number;
  occupiedSpots?: number;
  occupationIndex?: number;
  occupationIndexBadge?: string;
  occupationIndexLabel?: string;
  mediums?: Medium[];
  idStatus?: number;
  status?: Status;
  priority: string;
  priorityLabel: string;

  constructor(hub?) {
    super(hub);
  }

  parse(e: any): Hub {
    const hub = new Hub(e);
    return hub;
  }

  populate(hub) {
    if (hub.id) { this.id = hub.id; }
    if (hub.active) { this.active = hub.active; }
    this.name = hub.name;
    this.test = hub.test;
    this.code = hub.code;
    this.address = hub.address;
    if (hub.location) { this.location = new Point(hub.location); }
    if (hub.area) { this.area = new Polygon(hub.area); }
    if (hub.hubSpots) { this.hubSpots = this.setHubSpot(hub.hubSpots); }
    if (hub.hubSpots) { this.spotTypes = this.setSpotTypes(hub.hubSpots); }
    if (hub.totalSpots) { this.totalSpots = hub.totalSpots; }
    if (hub.occupiedSpots) { this.occupiedSpots = hub.occupiedSpots; }
    if (hub.totalSpots && hub.occupiedSpots) { this.freeSpots = hub.totalSpots - hub.occupiedSpots; }
    this.occupationIndex = hub.occupationIndex;
    this.occupationIndexLabel = `${Math.round(hub.occupationIndex * 100)}%`;
    this.occupationIndexBadge = this.getOccupationIndexBadge(hub.occupationIndex);
    this.mediums = (hub.mediums) ? this.setMediums(hub.mediums) : [];
    // Status from form
    if (hub.idStatus) {
      this.idStatus = hub.idStatus;
    }
    // Status from api
    if (hub.latestStatus) {
      this.idStatus = hub.latestStatus.idStatus;
      this.status = new Status(hub.latestStatus.status);
    }
    this.priority = hub.priority;
    this.priorityLabel = CatalogHubPriorityLabel[hub.priority];
  }

  setMediums?(mediums) {
    const temp = [];
    mediums.forEach( medium => {
      temp.push(new Medium(medium));
    });
    return temp;
  }

  setHubSpot(hubSpots) {
    const temp = [];
    hubSpots.forEach(hubSpot => {
      temp.push(new HubSpot(hubSpot));
    });
    return temp;
  }

  setSpotTypes(hubSpots) {
    const spotTypes = [];
    hubSpots.forEach( hubSpot => {
      spotTypes.push(`${(hubSpot.spotType.name)} (${hubSpot.quantity})`);
    });
    return spotTypes.join(', ');
  }

  getOccupationIndexBadge(occupationIndex) {
    if (occupationIndex >= 0 && occupationIndex < 0.5) { return 'green'; }
    if (occupationIndex >= 0.5 && occupationIndex < 0.75) { return 'yellow'; }
    if (occupationIndex >= 0.75) { return 'red'; }
  }
}
