import { Ticket } from './ticket';
import { Failure } from './failure';
import { TicketFailureField } from './ticket-failure-field';
import { BaseEntity } from './base-entity';

export class TicketFailure extends BaseEntity<TicketFailure> {
    id: number;
    address: string;
    idTicket: number;
    ticket?: Ticket;
    idFailure: number;
    failure?: Failure;
    fields?: TicketFailureField[];

    constructor(ticketFailure?) {
        super(ticketFailure);
    }

    parse(e: any): TicketFailure {
        const ticketFailure = new TicketFailure(e);
        return ticketFailure;
    }

    populate(ticketFailure) {
        this.id = ticketFailure.id;
        this.address = ticketFailure.address;
        this.idTicket = ticketFailure.idTicket;
        if (ticketFailure.ticket) { this.ticket = new Ticket(ticketFailure.ticket); }
        this.idFailure = ticketFailure.idFailure;
        if (ticketFailure.failure) { this.failure = new Failure(ticketFailure.failure); }
        if (ticketFailure.fields) { this.fields = this.setTicketFailureField(ticketFailure.fields); }
    }

    setTicketFailureField?(ticketFailureFields) {
        const temp = [];
        ticketFailureFields.forEach( field => {
            temp.push(new TicketFailureField(field));
        });
        return temp;
    }
}
