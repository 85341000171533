import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadService } from '../../../core-admin/services/uploadFile/upload-file.service';
import { UserService } from '../../../core-admin/services/user/user.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { EndpointsConstants } from '../../../helpers/endpoints';
import { CatalogImageClass } from '../../../helpers/catalog';

@Component({
  selector: 'app-modal-preview-image',
  templateUrl: './modal-preview-image.component.html',
  styleUrls: ['./modal-preview-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalPreviewImageComponent implements OnInit, OnChanges {
  allowedMimeTypes = 'image/jpeg,image/png';

  @Input() title = '';
  @Input() imageURL = '';
  @Input() showUpdateImage: boolean;
  @Input() imageClass: string;
  @Input() imageParameterName: string;
  @Input() idUser: number;

  @Output() updateImage = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
    private uploadService: UploadService,
    private userService: UserService,
    private toastr: ToastService
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
  }

  fileChangeListener(event: any) {
    const image = event.target.files[0];
    this.uploadService.uploadFiles(image, CatalogImageClass.base, this.imageClass, null, EndpointsConstants.file.image).subscribe( response => {
      const params = {
        [this.imageParameterName]: response.data['url']
      };
      this.userService.edit(params, this.idUser).subscribe( user => {
        this.imageURL = response.data['urlSigned'];
        this.updateImage.emit(user);
        this.toastr.success('Imagen reemplazada exitosamente!');
        this.activeModal.dismiss();
      });
    });
  }
}
