import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkshopReason } from 'src/app/models/workshop-reason';
import { WorkshopReasonService } from '../../../core-admin/services/workshop-reason/workshop-reason.service';
import { ReplacementPart } from 'src/app/models/replacement-part';
import { ReplacementPartService } from '../../../core-admin/services/replacement-part/replacement-part.service';
import { AdministratorService } from '../../../core-admin/services/administrator/administrator.service';
import { BaseResponse, BaseResponseData } from 'src/app/models/base-response';
import { Administrator } from 'src/app/models/administrator';
import { Status } from 'src/app/models/status';
import { StatusService } from '../../../core-admin/services/status/status.service';
import { CatalogApiScopes, CatalogApiTerms } from '../../../helpers/catalog';
import { WorkshopService } from '../../../core-admin/services/workshop/workshop.service';
import { Workshop } from 'src/app/models/workshop';
import { getFormArray, patchValueByItemArray } from '../../../helpers/utils-admin';
import { Log } from 'src/app/models/log';

@Component({
  selector: 'app-modal-form-workshop-order',
  templateUrl: './modal-form-workshop-order.component.html',
  styleUrls: ['./modal-form-workshop-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalFormWorkshopOrderComponent implements OnInit {
  workshopOrderForm: FormGroup;
  workshopReasons: WorkshopReason[] = [];
  replacementParts: ReplacementPart[] = [];
  administrators: Administrator[] = [];
  statuses: Status[] = [];
  workshop: Workshop;
  logs: BaseResponse<Log>;

  @Input() isForm: boolean;
  @Input() required = false;
  @Input() id: number;
  @Output() submitForm = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private workshopReasonsService: WorkshopReasonService,
    private replacementPartService: ReplacementPartService,
    private administratorService: AdministratorService,
    private statusService: StatusService,
    private workshopService: WorkshopService
  ) {
    this.workshopOrderForm = this.fb.group({
      reasons: [null, Validators.required],
      replacementParts: this.fb.array([]),
      idOperator: [null, Validators.required],
      idResponsable: [null, Validators.required],
      idStatus: [null, Validators.required],
      comment: []
    });
  }

  ngOnInit() {
    this.getWorkshopReasons();
    this.getReplacementParts();
    this.getOperators();
    this.getStatuses();
    if (this.id) {
      this.getWorkshopById();
      if (!this.isForm) {
        this.getLogs();
        this.workshopOrderForm.disable();
      }
    }
  }

  setReplacementParts(replacementPart?) {
    const replacementPartControl = this.fb.group({
      idReplacementPart: [(replacementPart && replacementPart.idReplacementPart) ? replacementPart.idReplacementPart : null, Validators.required],
      amount: [(replacementPart && replacementPart.amount) ? replacementPart.amount : null, Validators.required],
    });
    getFormArray('replacementParts', this.workshopOrderForm).push(replacementPartControl);
    if (!this.isForm) { replacementPartControl.disable(); }
  }

  removeFormArrayControl(controlName, index, formGroup) {
    getFormArray(controlName, formGroup).removeAt(index);
  }

  getFormArrayControls(controlName, formGroup) {
    return getFormArray(controlName, formGroup).controls;
  }

  getWorkshopById() {
    const filters = {
      scope: `${CatalogApiScopes.reasons},${CatalogApiScopes.replacementParts},${CatalogApiScopes.status}`
    };
    this.workshopService.getOne(this.id, filters).subscribe(response => {
      this.workshop = new Workshop(response.data);
      // Patch form
      this.workshopOrderForm.patchValue(this.workshop);
      // Patch workshop reasons
      patchValueByItemArray(this.workshopOrderForm, this.workshop.workshopReason, 'id', 'reasons');
      // Patch replacement parts
      this.workshop.replacementParts.forEach(replacementPart => {
        this.setReplacementParts(replacementPart);
      });
    });
  }

  getLogs() {
    this.workshopService.getLogs(this.id).subscribe( response => {
      this.logs = new BaseResponse<Log>(response, Log);
      this.cdr.detectChanges();
    });
  }

  getWorkshopReasons() {
    this.workshopReasonsService.get().subscribe( response => {
      this.workshopReasons = response.data['rows'];
      this.cdr.detectChanges();
    });
  }

  getOperators() {
    this.administratorService.get().subscribe( response => {
      const baseResponse = new BaseResponse(response, Administrator);
      this.administrators = baseResponse.data['rows'];
      this.cdr.detectChanges();
    });
  }

  getReplacementParts() {
    this.replacementPartService.get().subscribe( response => {
      this.replacementParts = response.data['rows'];
      this.cdr.detectChanges();
    });
  }

  getStatuses() {
    const filters = {
      scope: CatalogApiScopes.statusType,
      term: CatalogApiTerms.workshop
    };
    this.statusService.get(null, null, filters).subscribe( response => {
      this.statuses = response.data['rows'];
      this.cdr.detectChanges();
    });
  }

  onSubmit() {
    this.submitForm.emit(this.workshopOrderForm.value);
  }
}
