import { isNullOrEmptyOrUndefined } from 'src/app/helper/utils';
import { FormGroup, FormArray } from '@angular/forms';
import { CatalogMediumStatuses } from './catalog';

export function getClassToCropImage(url: string) {
  const image = new Image();
  image.src = url;
  return (image.height > image.width) ? 'landscape' : 'portrait';
}

export function imageBase64ToBlob(image) {
  const details = image.split(',');
  const byteString = atob(details[1]);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: 'image/jpeg' });
  return blob;
}

export function convertSecondsToTime(seconds: string) {
  let minuteValue = Math.floor(parseFloat(seconds) / 60).toString();
  if (minuteValue.length === 1) {
    minuteValue = `0${minuteValue}`;
  }
  let secondsValue = Math.round(parseFloat(seconds) % 60).toString();
  if (secondsValue.length === 1) {
    secondsValue = `0${secondsValue}`;
  }
  return minuteValue + ':' + secondsValue;
}

export function addOrderToFilters(filters, columnSettings) {
  const column = columnSettings.find(item => item.isActive === true);
  if (!isNullOrEmptyOrUndefined(column) && !isNullOrEmptyOrUndefined(filters)) {
    filters['order'] = (column.sortAsc) ? column.sortKey + ',ASC' : column.sortKey + ',DESC';
  }
  return filters;
}

export function getTimeDifferenceToNow(dateFrom: Date) {
  const from = dateFrom.getTime();
  const to = Date.now();
  return {
    seconds: Math.round((to - from) / 1000),
    minutes: Math.round((to - from) / 1000 / 60),
    hours: Math.round((to - from) / 1000 / 60 / 60),
    days: Math.round((to - from) / 1000 / 60 / 60 / 24)
  };
}

export function getFormArray(field: string, form: FormGroup) {
  return (form.get(field) as FormArray);
}

export function getBatteryPercent(batteryVolume) {
  // batteryVolume expressed in cV.
  // 5 is the % separator and 1700 is the cV correction to obtain a 5% separation between different charge levels
  let battery = (batteryVolume * 5) - 1700;
  if (battery > 100) { battery = 100; }
  if (battery < 0) { battery = 0; }
  return battery;
}

export function getBadgeClass(code: string) {
  switch (code) {
    case CatalogMediumStatuses.reserved:
      return 'yellow';
    case CatalogMediumStatuses.inHub:
    case CatalogMediumStatuses.inStreet:
    case CatalogMediumStatuses.inTrip:
      return 'cyan';
    case CatalogMediumStatuses.blockedForMaintenance:
    case CatalogMediumStatuses.blockedForSwinging:
    case CatalogMediumStatuses.blockedForWorkshop:
      return 'orange';
    case CatalogMediumStatuses.releasedForWorkshop:
    case CatalogMediumStatuses.swingingByOperator:
      return 'green';
    case CatalogMediumStatuses.inconsistent:
    case CatalogMediumStatuses.inWorkshop:
    case CatalogMediumStatuses.swingingByUser:
    case CatalogMediumStatuses.inMaintenance:
    case CatalogMediumStatuses.obsolete:
    case CatalogMediumStatuses.missing:
      return 'grey';
    case CatalogMediumStatuses.inHeadquarters:
      return 'grey-secondary';
  }
}

export function patchValueByItemArray(formGroup: FormGroup, items: any[], key: string, control: string) {
  const arr = [];
  items.forEach(item => {
    arr.push(item[key]);
  });
  formGroup.get(control).setValue(arr);
}

export function scrollContainerToBottom(element: HTMLElement) {
  element.scrollTop = Math.max(0, element.scrollHeight - element.offsetHeight);
}

export function convertEditorTextToPlainText(text: string) {
  return text.replace(/<\/p>/g, '</p>\r\n').replace(/<\/br>/g, '<br>\r\n').replace(/<[^>]*>/g, '');
}

export function dateRangeFilterStart() {
  const filters = {
    from: new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split('T', 1)[0],
    to: new Date().toISOString().split('T', 1)[0],
  };
  return filters;
}
